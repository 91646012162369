define("ember-cordova-keyboard/services/ember-cordova/keyboard", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _EmberRSVP = Ember.RSVP,
      Promise = _EmberRSVP.Promise;
  var KEYBOARD_ANIMATION_TIME = 300; //ms, guestimated from SO recommendations

  var _default = Ember.Service.extend(Ember.Evented, {
    adjustBodyHeight: true,
    keyboardHeight: 0,
    _listeners: null,
    _height: null,
    init: function init() {
      var _this = this;

      this._super();

      this._listeners = Ember.A();
      this.keyboard().then(function () {
        _this.setup();
      });
    },
    willDestroy: function willDestroy() {
      this._super();

      this.teardownListeners();
    },
    _keyboard: null,
    keyboard: function keyboard() {
      var _this2 = this;

      if (this._keyboard) {
        return Promise.resolve(this._keyboard);
      }

      return new Promise(function (resolve) {
        document.addEventListener("deviceready", function () {
          // The location of Keyboard moved between ionic-plugin-keyboard and
          // cordova-plugin-ionic-keyboard, but in order to ensure a smooth
          // upgrade, we check both.
          _this2._keyboard = window.Keyboard || window.cordova.plugins.Keyboard;
          resolve(_this2._keyboard);
        }, false);
      });
    },
    open: function open(element) {
      return this.keyboard().then(function (kb) {
        var elementShouldFocus = "activeElement" in document && document.activeElement !== element;

        if (kb.isVisible) {
          if (elementShouldFocus) {
            element.focus();
          }

          return true;
        }

        return new Promise(function (resolve) {
          if (elementShouldFocus) {
            element.focus();
          }

          Ember.run.later(function () {
            resolve();
          }, KEYBOARD_ANIMATION_TIME);
        });
      });
    },
    close: function close() {
      return this.keyboard().then(function (kb) {
        if (!kb.isVisible) {
          return true;
        }

        return new Promise(function (resolve) {
          kb.close();
          Ember.run.later(function () {
            resolve();
          }, KEYBOARD_ANIMATION_TIME);
        });
      });
    },
    setup: function setup() {
      var _this3 = this;

      var onKeyboardShow = this.onKeyboardShow.bind(this),
          onKeyboardHide = this.onKeyboardHide.bind(this),
          onKeyboardWillHide = this.onKeyboardWillHide.bind(this),
          onKeyboardWillShow = this.onKeyboardWillShow.bind(this),
          listeners = [{
        name: 'keyboardWillShow',
        fn: onKeyboardWillShow
      }, {
        name: 'keyboardWillHide',
        fn: onKeyboardWillHide
      }, {
        name: 'keyboardDidShow',
        fn: onKeyboardShow
      }, {
        name: 'keyboardDidHide',
        fn: onKeyboardHide
      }];
      listeners.forEach(function (listener) {
        _this3._listeners.pushObject(listener);

        window.addEventListener(listener.name, listener.fn, true);
      });
    },
    onKeyboardWillShow: function onKeyboardWillShow(e) {
      this.trigger('keyboardWillShow', e);
    },
    onKeyboardWillHide: function onKeyboardWillHide(e) {
      this.trigger('keyboardWillHide', e);
    },
    onKeyboardShow: function onKeyboardShow(e) {
      this.set('keyboardHeight', e.keyboardHeight);

      if (this.get('adjustBodyHeight')) {
        this._height = document.body.style.height || '';
        document.body.style.height = "calc(100% - " + e.keyboardHeight + "px)";
      }

      this.trigger('keyboardDidShow', e);
    },
    onKeyboardHide: function onKeyboardHide(e) {
      this.set('keyboardHeight', 0);

      if (this.get('adjustBodyHeight')) {
        document.body.style.height = this._height;
      }

      this.trigger('keyboardDidHide', e);
    },
    teardownListeners: function teardownListeners() {
      var _this4 = this;

      var listeners = this._listeners;
      listeners.forEach(function (listener) {
        window.removeEventListener(listener.name, listener.fn, true);

        _this4._listeners.removeObject(listener);
      });
    }
  });

  _exports.default = _default;
});