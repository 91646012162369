define("ember-cropster-models-table/components/c-table/data-loading-error/component", ["exports", "ember-cropster-models-table/components/c-table/data-loading-error/template"], function (_exports, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * This component is rendered if an error occurs while loading data in a table.
   *
   * @namespace EmberCropsterModelsTable.Component.CTable
   * @class DataLoadingError
   * @extends Ember.Component
   * @public
   */
  var _default = Ember.Component.extend({
    layout: _template.default,
    classNames: ['c-table__data-loading-error'],

    /**
     * The error that occurred.
     *
     * @attribute error
     * @type {Mixed}
     * @public
     */
    error: null,

    /**
     * The original data object passed in.
     *
     * @attribute data
     * @type {Object[]}
     * @public
     */
    data: null,

    /**
     * The currently visible columns.
     *
     * @attribute visibleColumns
     * @type {EmberCropsterModelsTable.Util.Column[]}
     * @public
     */
    visibleColumns: null,

    /**
     * The currently set page size.
     *
     * @attribute pageSize
     * @type {Number}
     * @public
     */
    pageSize: null,

    /**
     * An object with actions that is passed into all sub-components of c-table.
     *
     * @attribute actionHash
     * @type {Object}
     * @public
     */
    actionHash: null,

    /**
     * The error string to display.
     * This is built based on error.
     *
     * @property displayError
     * @type {String}
     * @readOnly
     * @protected
     */
    displayError: Ember.computed('error', function () {
      var error = Ember.get(this, 'error');

      if (Ember.typeOf(error) === 'object' || Ember.typeOf(error) === 'error' || Ember.typeOf(error) === 'instance') {
        var firstError = Ember.get(error, 'errors.firstObject') || Ember.get(error, 'payload.errors.firstObject');

        if (firstError && Ember.typeOf(firstError) === 'object' && firstError.detail) {
          return firstError.detail;
        }
      }

      return error;
    })
  });

  _exports.default = _default;
});