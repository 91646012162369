define("ember-cropster-models-table/components/c-table/page-info/component", ["exports", "ember-cropster-models-table/components/c-table/page-info/template"], function (_exports, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * This component is rendered to show the info about the current page/size.
   *
   * @namespace EmberCropsterModelsTable.Component.CTable
   * @class PageInfo
   * @extends Ember.Component
   * @public
   */
  var _default = Ember.Component.extend({
    layout: _template.default,
    classNames: ['c-table__footer__page-info'],

    /**
     * The currently visible item count.
     *
     * @attribute currentItemCount
     * @type {Number}
     * @public
     */
    currentItemCount: null,

    /**
     * The total item count, accross all pages.
     *
     * @attribute totalItemCount
     * @type {Number}
     * @public
     */
    totalItemCount: null,

    /**
     * The current page.
     *
     * @attribute currentPage
     * @type {Number}
     * @public
     */
    currentPage: null,

    /**
     * The current page size.
     *
     * @attribute pageSize
     * @type {Number}
     * @public
     */
    pageSize: null,

    /**
     * The original data passed into the table.
     *
     * @attribute data
     * @type {Array}
     * @public
     */
    data: null,

    /**
     * If there was a loading error.
     *
     * @attribute hasError
     * @type {Boolean}
     * @public
     */
    hasError: false,

    /**
     * The currently selected items.
     *
     * @attribute selectedItems
     * @type {Object[]}
     * @public
     */
    selectedItems: null,

    /**
     * The currently visible columns.
     *
     * @attribute visibleColumns
     * @type {EmberCropsterModelsTable.Util.Column[]}
     * @public
     */
    visibleColumns: null,

    /**
     * An object with actions that is passed into all sub-components of c-table.
     *
     * @attribute actionHash
     * @type {Object}
     * @public
     */
    actionHash: null,

    /**
     * The first visible item index. (1-based)
     *
     * @property firstItem
     * @type {Number}
     * @readOnly
     * @protected
     */
    firstItem: Ember.computed('currentPage', 'pageSize', function () {
      var currentPage = Ember.get(this, 'currentPage');
      var pageSize = Ember.get(this, 'pageSize');
      return (currentPage - 1) * pageSize + 1;
    }),

    /**
     * The last visible item index. (1-based)
     *
     * @property lastItem
     * @type {Number}
     * @readOnly
     * @protected
     */
    lastItem: Ember.computed('firstItem', 'currentItemCount', function () {
      var firstItem = Ember.get(this, 'firstItem');
      var currentItemCount = Ember.get(this, 'currentItemCount');
      return firstItem + currentItemCount - 1;
    })
  });

  _exports.default = _default;
});