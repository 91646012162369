define("ember-cropster-models-table/components/c-table/page-size-select/component", ["exports", "ember-cropster-models-table/components/c-table/page-size-select/template"], function (_exports, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * The component to select the page size.
   *
   * @namespace EmberCropsterModelsTable.Component.CTable
   * @class PageSizeSelect
   * @extends Ember.Component
   * @public
   */
  var _default = Ember.Component.extend({
    layout: _template.default,
    classNames: ['c-table__page-size-select'],

    /**
     * The currently selected page size.
     *
     * @attribute pageSize
     * @type {Number}
     * @public
     */
    pageSize: null,

    /**
     * The page size options to select from.
     * This is an array of numbers.
     *
     * @attribute pageSizeOptions
     * @type {Number[]}
     * @public
     */
    pageSizeOptions: null,

    /**
     * An object with actions that is passed into all sub-components of c-table.
     *
     * @attribute actionHash
     * @type {Object}
     * @public
     */
    actionHash: null,

    /**
     * The action to call to update the page size.
     *
     * @event updatePageSize
     * @param {Number} pageSize
     * @public
     */
    updatePageSize: null
  });

  _exports.default = _default;
});