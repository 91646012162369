define("ember-cropster-models-table/components/c-table-paginated/quick-action-bar/component", ["exports", "ember-cropster-models-table/components/c-table-paginated/quick-action-bar/template"], function (_exports, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * The quick action bar for the paginated table.
   * This contains actions for the table, especially bulk actions for the selected items.
   *
   * @namespace EmberCropsterModelsTable.Component.CTablePaginated
   * @class QuickActionBar
   * @extends Ember.Component
   * @public
   */
  var _default = Ember.Component.extend({
    layout: _template.default,

    /**
     * If bulk actions should be shown.
     *
     * @attribute hasActions
     * @type {Boolean}
     * @default true
     * @public
     */
    hasActions: true,

    /**
     * The currently selected items.
     *
     * @attribute selectedItems
     * @type {Object[]}
     * @public
     */
    selectedItems: null,

    /**
     * The base data query.
     *
     * @attribute data
     * @type {Object[]}
     * @public
     */
    data: null,

    /**
     * The currently used tableId.
     *
     * @attribute tableId
     * @type {String}
     * @public
     */
    tableId: null,

    /**
     * The general data from the paginated table controller.
     *
     * @attribute paginatedTableData
     * @type {Object[]}
     * @public
     */
    paginatedTableData: null,

    /**
     * The max. number of pages.
     *
     * @attribute maxPageCount
     * @type {Number}
     * @public
     */
    maxPageCount: null,

    /**
     * The count of total items across all pages.
     *
     * @attribute totalItemCount
     * @type {Number}
     * @public
     */
    totalItemCount: null,

    /**
     * The number of items on the current page.
     *
     * @attribute currentItemCount
     * @type {Number}
     * @public
     */
    currentItemCount: null,

    /**
     * The action hash that is passed into all sub-components.
     *
     * @attribute actionHash
     * @type {Object}
     * @public
     */
    actionHash: null,

    /**
     * The message to display if no items are selected.
     *
     * @attribute emptyMessage
     * @type {String}
     * @default 'Select records to see actions'
     * @public
     */
    emptyMessage: null,

    /**
     * Allow selecting of all items.
     *
     * @attribute allowSelectAll
     * @type {Boolean}
     * @default true
     * @public
     */
    allowSelectAll: true,

    /**
     * If the table has any filters or any selection.
     *
     * @property hasFiltersOrSelection
     * @type {Boolean}
     * @readOnly
     * @protected
     */
    hasFiltersOrSelection: Ember.computed.readOnly('paginatedTableData.hasFiltersOrSelection'),

    /**
     * If the table has changed any filters from the default filters.
     *
     * @property hasChangedFilters
     * @type {Boolean}
     * @readOnly
     * @protected
     */
    hasChangedFilters: Ember.computed.readOnly('paginatedTableData.hasChangedFilters'),

    /**
     * The currently selected items' ids.
     *
     * @property selectedIds
     * @type {String[]}
     * @readOnly
     * @protected
     */
    selectedIds: Ember.computed.mapBy('selectedItems', 'id')
  });

  _exports.default = _default;
});