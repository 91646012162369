define("ember-cropster-models-table/components/c-table/row/component", ["exports", "ember-cropster-models-table/components/c-table/row/template"], function (_exports, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * A single data row for the table.
   * This is responsible to render the td's.
   *
   * @namespace EmberCropsterModelsTable.Component.CTable
   * @class Row
   * @extends Ember.Component
   * @public
   */
  var _default = Ember.Component.extend({
    layout: _template.default,
    tagName: 'tr',
    classNameBindings: ['isSelected:row--selected'],
    // This is just used for tests
    // If you provide your own, custom row, you might not want this. E.g. if you use a tagless row component
    'data-test-models-table-row': true,

    /**
     * The record (item) for this row.
     *
     * @attribute record
     * @type {Object}
     * @public
     *
     */
    record: null,

    /**
     * All the initial data for this table.
     *
     * @attribute data
     * @type {Object[]}
     * @public
     */
    data: null,

    /**
     * The index of this row, starting with 0 for the first row.
     *
     * @attribute rowIndex
     * @type {Number}
     * @public
     */
    rowIndex: null,

    /**
     * The currently visible columns.
     *
     * @attribute visibleColumns
     * @type {EmberCropsterModelsTable.Util.Column[]}
     * @public
     */
    visibleColumns: null,

    /**
     * The currently selected items.
     *
     * @attribute selectedItems
     * @type {Object[]}
     * @public
     */
    selectedItems: null,

    /**
     * An object with actions that is passed into all sub-components of c-table.
     *
     * @attribute actionHash
     * @type {Object}
     * @public
     */
    actionHash: null,

    /**
     * If the row is selected.
     *
     * @property isSelected
     * @type {Boolean}
     * @readOnly
     * @protected
     */
    isSelected: Ember.computed('selectedItems.@each.id', 'record.id', function () {
      var selectedItems = Ember.get(this, 'selectedItems');
      var recordId = Ember.get(this, 'record.id');
      return selectedItems && recordId && !!selectedItems.findBy('id', recordId);
    })
  });

  _exports.default = _default;
});