define("ember-cropster-common/helpers/format-nl2br", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.escapeHTML = escapeHTML;
  _exports.formatNl2br = formatNl2br;
  _exports.default = void 0;

  function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _nonIterableRest(); }

  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance"); }

  function _iterableToArrayLimit(arr, i) { var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

  function escapeHTML(str) {
    if (!document) {
      return str;
    }

    return document.createElement('div').appendChild(document.createTextNode(str)).parentNode.innerHTML;
  }

  function formatNl2br(_ref) {
    var _ref2 = _slicedToArray(_ref, 1),
        str = _ref2[0];

    var _ref3 = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {},
        _ref3$escape = _ref3.escape,
        escape = _ref3$escape === void 0 ? true : _ref3$escape;

    var breakTag = '<br>';

    if (Ember.String.isHTMLSafe(str)) {
      str = str.toString();
    }

    if (Ember.typeOf(str) !== 'string') {
      return '';
    } // First, escape all tags in there


    if (escape) {
      str = escapeHTML(str);
    }

    return Ember.String.htmlSafe(str.replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, "$1".concat(breakTag, "$2")));
  }
  /**
   * A helper to add line breaks to a text.
   * Note that this will escape other characters in the string!
   *
   * @class FormatNl2br
   * @namespace Helper
   * @extends Ember.Helper
   * @public
   * @example
   * ```handlebars
   * {{format-nl2br "1
   * 2
   * 3"}}
   * ```
   */


  var _default = Ember.Helper.helper(formatNl2br);

  _exports.default = _default;
});