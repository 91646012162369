define("ember-l10n/services/l10n", ["exports", "ember-get-config", "ember-l10n/utils/guess-locale"], function (_exports, _emberGetConfig, _guessLocale) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.strfmt = _exports.default = void 0;

  function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _nonIterableRest(); }

  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance"); }

  function _iterableToArrayLimit(arr, i) { var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

  /**
   * This service translates through gettext.js.
   * There are two available methods to be used
   * for translations message ids from JS source:
   *
   * - t(msgid, hash);
   * - tVar(msgid, hash);
   * - n(msgid, msgidPlural, count, hash);
   *
   * Furthermore, there's an auto initialization
   * feature (default: true), which detects user's
   * locale according to system preferences. If the
   * user's locale is supported in `availableLocales`,
   * the corresponding translations are loaded. If the
   * user's locale is not supported, the default locale
   * will be used instead (default: 'en'). Please use the
   * following method to change locales:
   *
   * - setLocale(locale);
   *
   * The following utility methods are also availalbe:
   *
   * - hasLocale(locale);
   * - detectLocale();
   *
   * To configure the path of the JSON files (depending on
   * the path configured via gettext.sh extractor) use the
   * `jsonPath` property (default: '/assets/locales').
   *
   * @namespace Service
   * @class L10n
   * @extends Ember.Service
   * @extends Ember.Evented
   * @public
   */
  var _default = Ember.Service.extend({
    // -------------------------------------------------------------------------
    // Dependencies
    assetMap: Ember.inject.service(),
    // -------------------------------------------------------------------------
    // Properties

    /**
     * Current locale from user, defaults
     * to 'defaultLocale' if not retrievable
     * or currently available due to missing
     * translations.
     *
     * @property locale
     * @type {String}
     * @default null
     * @public
     */
    locale: null,

    /**
     * Use this property if you want to force
     * a specific locale and skip automatic
     * detection of user's system settings.
     * This is useful for signed in users,
     * but beware that unsupported locales
     * will fallback to the default locale!
     *
     * @property forceLocale
     * @type {String}
     * @default null
     * @public
     */
    forceLocale: null,

    /**
     * Fallback locale for unavailable locales or
     * the language which is used for message ids.
     *
     * @property defaultLocale
     * @type {String}
     * @default 'en'
     * @public
     */
    defaultLocale: 'en',

    /**
     * Fallback plural form for unavaiable locales or
     * the language which is used for message ids.
     *
     * @property defaultPluralForm
     * @type {String}
     * @default 'nplurals=2; plural=(n != 1)'
     * @public
     */
    defaultPluralForm: 'nplurals=2; plural=(n != 1);',

    /**
     * Will invoke a language detection or loads
     * language from `forceLanguage` on service
     * instantiation. If disabling, make sure to
     * set locale manually with setLocale().
     *
     * @property autoInitialize
     * @type {String}
     * @default null
     * @public
     */
    autoInitialize: true,

    /**
     * Directory containing JSON files with all
     * translations for corresponding locale.
     *
     * @property jsonPath
     * @type {String}
     * @default 'assets/locales'
     * @public
     */
    jsonPath: Ember.computed(function () {
      return '/assets/locales'; // Use a cp here to prevent broccoli-asset-rev from changing this
    }),

    /**
     * If the assetMap.json generated by broccoli-asset-rev should be used.
     * This assumes that fingerprinting is activated, as well as the `generateAssetMap` option is set to true.
     * On init, it will load the asset map, and then load the locales based on the asset map.
     *
     * Override and set this to false if you want to have the legacy behaviour,
     * where it manually fingerprints the files with the current app version.
     *
     * By default, this is true if ifa is enabled in your config/environment.js.
     *
     * @property useAssetMap
     * @type {Boolean}
     * @default true
     * @protected
     */
    useAssetMap: Ember.computed(function () {
      return Ember.get(_emberGetConfig.default, 'ifa.enabled');
    }),

    /**
     * Currently available translations hash.
     *
     * @property availableLocales
     * @type {Object}
     * @public
     */
    availableLocales: Ember.computed('locale', function () {
      return {
        'en': this.t('en')
      };
    }),

    /**
     * Wrapper for window object for mocking tests.
     *
     * @property _window
     * @type {Object}
     * @readOnly
     * @private
     */
    _window: Ember.computed(function () {
      return window || {};
    }),

    /**
     * Hashmap storing callable plural function
     * for each target language parsed from the
     * `plural-form` header of JSON files.
     *
     * @property _data
     * @type {Object}
     * @default {}
     * @private
     */
    _plurals: Ember.computed(function () {
      var pluralForm = Ember.get(this, 'defaultPluralForm');
      var locale = Ember.get(this, 'defaultLocale');
      var _plurals = {};
      _plurals[locale] = this._pluralFactory(pluralForm);
      return _plurals;
    }),

    /**
     * Hashmap storing loaded translations by
     * locale at runtime to avoid requests on
     * consequent invocations of `setLocale()`.
     *
     * @property _data
     * @type {Object}
     * @default {}
     * @private
     */
    _data: Ember.computed(function () {
      return {};
    }),

    /**
     * The map of locales to locale files to use.
     * This is built by the `_registerLocaleFiles()` method, on `init()`.
     *
     * @property _localeMap
     * @type {Object}
     * @private
     */
    _localeMap: null,
    // -------------------------------------------------------------------------
    // Methods

    /**
     * Sets initial locale. If you want to to
     * skip language detection, please provide
     * `forceLocale` property with reopen().
     *
     * @method init
     * @return {Void}
     * @public
     */
    init: function init() {
      this._super.apply(this, arguments);

      this._registerLocaleFiles();

      if (Ember.get(this, 'autoInitialize')) {
        this.setLocale(this.detectLocale());
      }
    },

    /**
     * Provides current locale. If not set,
     * delivers default locale.
     *
     * @method setLocale
     * @param {String} locale
     * @return {String}
     * @public
     */
    getLocale: function getLocale() {
      var defaultLocale = Ember.get(this, 'defaultLocale');
      var locale = Ember.get(this, 'locale');
      return locale || defaultLocale;
    },

    /**
     * Sets active locale if available. Returns a
     * RSPV Promise for asynchronous JSON request.
     *
     * @method setLocale
     * @param {String} locale
     * @return {RSVP.Promise}
     * @public
     */
    setLocale: function setLocale(locale) {
      var _this = this;

      return new Ember.RSVP.Promise(function (resolve, reject) {
        if (!_this.hasLocale(locale, true)) {
          reject();
          return;
        }

        var successCallback = function successCallback() {
          Ember.set(_this, 'locale', locale);
          resolve();
        };

        _this._loadJSON(locale).then(successCallback, reject);
      });
    },

    /**
     * Checks if locale is available.
     *
     * @method setLocale
     * @param {String} locale
     * @param {Boolean} warnIfUnavailable
     * @return {Boolean}
     * @public
     */
    hasLocale: function hasLocale(locale) {
      var warnIfUnavailable = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
      var availableLocales = Ember.get(this, 'availableLocales');
      var hasLocale = !!availableLocales[locale];

      if (!hasLocale && warnIfUnavailable) {
        this._log("Locale \"".concat(locale, "\" is not available!"), 'warn');
      }

      return hasLocale;
    },

    /**
     * Gets user's current client language and
     * provides extracted ISO-Code.
     *
     * @method detectLocale
     * @return {String}
     * @public
     */
    detectLocale: function detectLocale() {
      var defaultLocale = Ember.get(this, 'defaultLocale');
      var forceLocale = Ember.get(this, 'forceLocale'); // auto detect locale if no force locale

      var locale = forceLocale || this.guessBrowserLocale(); // provide default locale if not available

      if (!this.hasLocale(locale)) {
        this._log("Falling back to default language: \"".concat(defaultLocale, "\"!"));

        return defaultLocale;
      } // otherwise return detected locale


      if (!forceLocale) {
        this._log("Automatically detected user language: \"".concat(locale, "\""));
      } else {
        this._log("Using forced locale: \"".concat(locale, "\""));
      }

      return locale;
    },

    /**
     * Get a list of desireable locales for the browser.
     * This will use navigator.languages if possible, and fall back to navigator.browserLanguage for IE11.
     *
     * @method _getBrowserLocales
     * @returns {Array<String>}
     * @private
     */
    _getBrowserLocales: function _getBrowserLocales() {
      var navigator = Ember.get(this, '_window.navigator');
      var defaultLocale = Ember.get(this, 'defaultLocale') || 'en';

      if (!navigator) {
        return Ember.A([defaultLocale]);
      }

      var desiredLocales = navigator.languages || [navigator.browserLanguage];
      desiredLocales = Ember.A(desiredLocales.slice()).compact();

      if (!Ember.get(desiredLocales, 'length')) {
        return Ember.A([defaultLocale]);
      }

      return desiredLocales;
    },

    /**
     * Guess the best locale to use based on the browser locales & available locales.
     *
     * @method guessBrowserLocale
     * @return {String}
     * @public
     */
    guessBrowserLocale: function guessBrowserLocale() {
      var allowSubLocales = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;
      var defaultLocale = Ember.get(this, 'defaultLocale') || 'en';
      var availableLocales = Object.keys(Ember.get(this, 'availableLocales'));

      var desiredLocales = this._getBrowserLocales();

      return (0, _guessLocale.guessLocale)(availableLocales, desiredLocales, {
        defaultLocale: defaultLocale,
        allowSubLocales: allowSubLocales
      });
    },

    /**
     * Translates a singular form message id.
     *
     * @method t
     * @param {String} msgid
     * @param {Object} hash
     * @param {String} msgctxt
     * @return {String}
     * @public
     */
    t: function t(msgid) {
      var hash = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
      var msgctxt = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : '';

      var key = this._sanitizeKey(msgid);

      if (Ember.typeOf(key) !== 'string') {
        return msgid;
      }

      var _this$_getMessages = this._getMessages(key, msgctxt),
          _this$_getMessages2 = _slicedToArray(_this$_getMessages, 1),
          message = _this$_getMessages2[0];

      return strfmt(message || key, hash);
    },

    /**
     * Translates a plural form message id.
     *
     * @method n
     * @param {String} msgid
     * @param {String} msgidPlural
     * @param {Number} count
     * @param {Object} hash
     * @param {String} msgctxt
     * @return {String}
     * @public
     */
    n: function n(msgid, msgidPlural) {
      var count = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 1;
      var hash = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : {};
      var msgctxt = arguments.length > 4 && arguments[4] !== undefined ? arguments[4] : '';

      var sKey = this._sanitizeKey(msgid);

      if (Ember.typeOf(sKey) !== 'string') {
        return msgid;
      }

      var pKey = this._sanitizeKey(msgidPlural);

      if (Ember.typeOf(pKey) !== 'string') {
        return msgid;
      }

      var plural = 0;
      var message = '';
      var locale = this.getLocale();

      var messages = this._getMessages(sKey, msgctxt);

      var pluralFunc = Ember.get(this, "_plurals.".concat(locale));

      if (Ember.typeOf(pluralFunc) === 'function') {
        var _pluralFunc = pluralFunc(count);

        plural = _pluralFunc.plural;
        message = messages[plural];
      }

      message = message || (plural ? pKey : sKey);
      return strfmt(message, Ember.assign({
        count: count
      }, hash));
    },

    /**
     * Translates a contextual singular form message id.
     *
     * @method pt
     * @param {String} msgid
     * @param {String} msgctxt
     * @param {Object} hash
     * @return {String}
     * @public
     */
    pt: function pt(msgid, msgctxt) {
      var hash = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
      return this.t(msgid, hash, msgctxt);
    },

    /**
     * Translates a contextual plural form message id.
     *
     * @method pn
     * @param {String} msgid
     * @param {String} msgidPlural
     * @param {Number} count
     * @param {String} msgctxt
     * @param {Object} hash
     * @return {String}
     * @public
     */
    pn: function pn(msgid, msgidPlural, count, msgctxt) {
      var hash = arguments.length > 4 && arguments[4] !== undefined ? arguments[4] : {};
      return this.n(msgid, msgidPlural, count, hash, msgctxt);
    },

    /**
     * Translate a singular string without indexing it.
     * This is useful when passing variables to it, e.g. `l10n.tVar(myVar)`
     * If you would use `l10n.t(myVar)` in this case, myVar would be (wrongly) parsed by `gettext.sh`.
     *
     * @method tVariable
     * @param {String} msgid
     * @param {Object} hash
     * @return {String}
     * @public
     */
    tVar: function tVar(msgid) {
      var hash = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
      return this.t(msgid, hash);
    },

    /**
     * Checks if a message id exists for current locale.
     *
     * @method exists
     * @param {String} msgid
     * @return {Boolean}
     * @public
     */
    exists: function exists(msgid) {
      var msgctxt = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : '';

      var key = this._sanitizeKey(msgid);

      if (Ember.typeOf(key) !== 'string') {
        return false;
      }

      return !!this._readKey(key, msgctxt);
    },

    /**
     * Reads JSON data for given message id containing an array like:
     *
     * ```
     * [
     *   'ID of plural message' || null,
     *   'Translated singular',
     *   'Translated plural'
     * ]
     * ```
     *
     * @method _getMessages
     * @param {String} key
     * @param {String} ctxt
     * @return {Array}
     * @private
     */
    _getMessages: function _getMessages(key) {
      var ctxt = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : '';

      var json = this._readKey(key, ctxt);

      if (json === null) {
        return [];
      }

      return json.msgstr || [];
    },

    /**
     * Tries to lookup JSON data for given key and context.
     *
     * @method _readKey
     * @param {String} key
     * @param {String} ctxt
     * @return {Object|null}
     * @private
     */
    _readKey: function _readKey(key) {
      var ctxt = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : '';
      var locale = Ember.get(this, 'locale');

      var _data = Ember.get(this, '_data');

      var json = _data[locale] || {};
      json = json.translations || {};
      json = json[ctxt] || json[''] || {};
      return json[key] || null;
    },

    /**
     * Sanitizes message ids by removing unallowed characters like whitespace.
     *
     * @method _sanitizeKey
     * @param {String} key
     * @return {String}
     * @private
     */
    _sanitizeKey: function _sanitizeKey(key) {
      if (Ember.typeOf(key) !== 'string') {
        try {
          key = key.toString();
        } catch (e) {
          this._log('Message ids should be either a string or an object implementing toString() method!');

          return key;
        }
      }

      return key.replace(/\s+/g, ' ');
    },

    /**
     * Loads current locale translation file.
     * Note that `locale` will trigger change
     * after loading JSON file, so watching the
     * `locale` informs about new translations!
     *
     * @method _loadJSON
     * @param {String} locale
     * @return {Void}
     * @private
     */
    _loadJSON: function _loadJSON(locale) {
      var _this2 = this;

      return new Ember.RSVP.Promise(function (resolve, reject) {
        var _data = Ember.get(_this2, '_data');

        var successCallback = function successCallback(response) {
          if (Ember.get(_this2, 'isDestroyed')) {
            return;
          }

          _this2._saveJSON(response, locale);

          resolve();
        };

        var failureCallback = function failureCallback(reason) {
          if (Ember.get(_this2, 'isDestroyed')) {
            return;
          }

          _this2._log("An error occurred loading locale \"".concat(locale, "\": ").concat(reason), 'error');

          reject(reason);
        }; // used cached translation from hash map


        if (_data.hasOwnProperty(locale)) {
          successCallback(_data[locale]);
          resolve(_data[locale]);
          return;
        } // otherwise load json file from assets


        _this2._loadLocaleFile(locale).then(successCallback, failureCallback);
      });
    },

    /**
     * Saves locale's translation data in internal hash and extracts plural
     * form from `headers` to convert it to a callable for plural methods.
     *
     * We ignore the `language` in the JSON here, as we don't really care about that.
     * Instead, we just use the locale used for loading the JSON.
     *
     * @method _saveJSON
     * @param {Object} response
     * @param {String} locale
     * @return {Void}
     * @private
     */
    _saveJSON: function _saveJSON(response, locale) {
      var json = this._sanitizeJSON(response);

      var pluralForm = json.headers['plural-forms'];
      Ember.set(this, "_data.".concat(locale), json);
      Ember.set(this, "_plurals.".concat(locale), this._pluralFactory(pluralForm, locale));
    },

    /**
     * Normalize the message ids in the JSON response
     * Otherwise, the lookup in this._getMessages() can sometimes fail.
     * The new extraction functionality leaves (some) whitespace intact, making message ids with e.g. newlines possible.
     * This breaks when looking up message keys.
     * To fix this, we normalize all message ids to plain whitespace.
     * Newlines and other whitespace in the message content remains intact.
     * This also ensures that with changing whitespace, messages will still be found later.
     *
     * @method _sanitizeJSON
     * @param {Object} json
     * @return {Object}
     * @private
     */
    _sanitizeJSON: function _sanitizeJSON(json) {
      var translations = json.translations;
      var sanitizedTranslations = {};
      Object.keys(translations).forEach(function (context) {
        var items = translations[context];
        sanitizedTranslations[context] = {};
        Object.keys(items).forEach(function (messageId) {
          var item = items[messageId];
          var sanitizedMessageId = messageId.replace(/\s+/g, ' ');
          sanitizedTranslations[context][sanitizedMessageId] = Ember.assign({}, item, {
            msgid: sanitizedMessageId
          });
        });
      });
      return Ember.assign({}, json, {
        translations: sanitizedTranslations
      });
    },

    /**
     * Transforms and stores plural form it into a callable function.
     *
     * @method _pluralFactory
     * @param {String} pluralForm
     * @param {String} locale
     * @return {Void}
     * @private
     */
    _pluralFactory: function _pluralFactory(pluralForm) {
      var defaultPluralForm = Ember.get(this, 'defaultPluralForm');

      if (!pluralForm || !pluralForm.match(/^\s*nplurals=\s*[\d]+\s*;\s*plural\s*=\s*(?:[-+*/%?!&|=<>():;n\d\s]+);$/)) {
        this._log("Plural form \"".concat(pluralForm, "\" is invalid: 'nplurals=NUMBER; plural=EXPRESSION;' - falling back to ").concat(defaultPluralForm, "!"));

        pluralForm = defaultPluralForm;
      }

      return new Function('n', "\n      var nplurals, plural; ".concat(pluralForm, "\n\n      switch (typeof plural) {\n        case 'boolean':\n          plural = plural ? 1 : 0;\n          break;\n        case 'number':\n          plural = plural;\n          break;\n        default:\n          plural = 0;\n      }\n\n      var max = nplurals - 1;\n      if (plural > max) {\n        plural = 0;\n      }\n\n      return {\n        plural: plural,\n        nplurals: nplurals\n      };\n    "));
    },

    /**
     * Build the locale map for the given available locales.
     *
     * @method _registerLocaleFiles
     * @private
     */
    _registerLocaleFiles: function _registerLocaleFiles() {
      var _this3 = this;

      var locales = Ember.get(this, 'availableLocales') || {};
      var localeMap = {};
      Object.keys(locales).forEach(function (locale) {
        (true && !(locale !== 'zh') && Ember.assert('Do not use the locale zh, as it is not a valid locale. Instead, use dedicated locales for traditional & simplified Chinese.', locale !== 'zh'));
        localeMap[locale] = _this3._getPathForLocale(locale);
      });
      Ember.set(this, '_localeMap', localeMap);
      return localeMap;
    },

    /**
     * Load a locale file.
     *
     * @method loadLocaleFile
     * @param {String} locale The name of the locale to load, e.g. en
     * @return {RSVP.Promise}
     * @public
     */
    _loadLocaleFile: function _loadLocaleFile(locale) {
      var localeMap = Ember.get(this, '_localeMap');
      var fileName = Ember.get(localeMap, locale);
      return this.ajaxRequest(fileName);
    },

    /**
     * Actually make the Ajax request.
     *
     * @method ajaxRequest
     * @param {String} fileName
     * @return {Promise<Object>}
     * @protected
     */
    ajaxRequest: function ajaxRequest(fileName) {
      return new Ember.RSVP.Promise(function (resolve, reject) {
        var request = new XMLHttpRequest();
        request.open('GET', fileName);
        request.addEventListener('load', function () {
          try {
            var responseText = this.responseText;
            var json = JSON.parse(responseText);
            resolve(json);
          } catch (error) {
            reject(error);
          }
        });
        request.addEventListener('error', reject);
        request.send();
      });
    },

    /**
     * Gets complete path to a locale file.
     *
     * @private
     * @method _getPathForLocale
     * @param {String} file
     * @return {String}
     */
    _getPathForLocale: function _getPathForLocale(locale) {
      var basePath = Ember.get(this, 'jsonPath');
      var assetMap = Ember.get(this, 'assetMap');
      var fullPath = "".concat(basePath, "/").concat(locale, ".json");

      if (!Ember.get(this, 'useAssetMap')) {
        return fullPath;
      }

      var path = fullPath;

      if (path.indexOf('/') === 0) {
        path = path.substr(1);
      }

      return assetMap.resolve(path) || fullPath;
    },

    /**
     * Log a message.
     * When testing, this will be swallowed to keep the output clean.
     *
     * @method _log
     * @param {String} str
     * @param {'log'|'warn'|'error'} type
     * @private
     */
    _log: function _log(str) {
      var type = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'log';

      // @todo: remove on resolution of public modules API
      // https://github.com/Cropster/ember-l10n/issues/21
      if (Ember.testing) {
        return;
      }

      if (!['log', 'warn', 'error'].indexOf(type) === -1) {
        type = 'log';
      } // eslint-disable-next-line no-console


      console[type]("l10n.js: ".concat(str));
    }
  });
  /**
   * Replaces placeholders like {{placeholder}} from string.
   *
   * @public
   * @method strfmt
   * @param {String} string
   * @param {Object} hash
   * @return {String}
   */


  _exports.default = _default;

  var strfmt = function strfmt(string, hash) {
    // ignore each invalid hash param
    if (Ember.typeOf(hash) !== 'object') {
      return string;
    } // find all: {{placeholderName}}


    var pattern = /{{\s*([\w]+)\s*}}/g;

    var replace = function replace(idx, match) {
      var value = hash[match];
      return Ember.isNone(value) ? "{{".concat(match, "}}") : value;
    };

    return string.replace(pattern, replace);
  };

  _exports.strfmt = strfmt;
});