define("ember-cropster-common/mixins/scroll-to-id", ["exports", "ember-cropster-common/utils/dom/element-offset", "ember-cropster-common/utils/dom/scroll-to"], function (_exports, _elementOffset, _scrollTo) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _Ember = Ember,
      testing = _Ember.testing;
  /**
   * On transition, scroll to element with ID of hash, if set.
   * e.g. /route#my-element will try to scroll to `<div id='my-element'></div>`.
   *
   * @namespace EmberCropsterCommon.Mixin
   * @class ScrollToId
   * @extends Ember.Mixin
   * @public
   */

  var _default = Ember.Mixin.create({
    actions: {
      didTransition: function didTransition() {
        this._super.apply(this, arguments);

        return new Ember.RSVP.Promise(function (resolve) {
          Ember.run.later(function () {
            var hash = window.location.hash; // Allow to override - this is for tests only

            if (window.locationHashOverride) {
              hash = window.locationHashOverride;
            }

            if (!hash) {
              resolve();
              return;
            }

            var body = testing ? document.querySelector('#ember-testing-container') : window;
            var el = document.querySelector(hash);

            if (el) {
              var offset = 50;
              var scrollTop = testing ? el.offsetTop / 2 : (0, _elementOffset.default)(el).top;
              (0, _scrollTo.scrollTo)(body, {
                top: scrollTop - offset,
                left: 0,
                behavior: 'smooth'
              });
              Ember.run.later(resolve, 500);
            } else {
              resolve();
            }
          }, 100);
        });
      }
    }
  });

  _exports.default = _default;
});