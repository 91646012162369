define('ember-keen/utils/merge-deep', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = mergeDeep;

  function _toConsumableArray(arr) {
    if (Array.isArray(arr)) {
      for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
        arr2[i] = arr[i];
      }

      return arr2;
    } else {
      return Array.from(arr);
    }
  }

  function isObject(obj) {
    return Ember.typeOf(obj) === 'object';
  }

  function isArray(obj) {
    return Ember.typeOf(obj) === 'array';
  }

  function isCloneableInstance(obj) {
    return Ember.typeOf(obj) === 'instance' && Ember.typeOf(obj.clone) === 'function';
  }

  /**
   * Deep-merge objects without mutating them.
   * In contrast to e.g. $.extend(), this will NOT mutate the first given parameter, but just return the merged object.
   *
   * @namespace EmberCropsterCommon.Util
   * @method mergeDeep
   * @param {...Object} objects A list of objects to merge
   * @return {Object} The merged object
   * @public
   */
  function mergeDeep() {
    for (var _len = arguments.length, objects = Array(_len), _key = 0; _key < _len; _key++) {
      objects[_key] = arguments[_key];
    }

    return objects.reduce(function (prev, obj) {
      Object.keys(obj).forEach(function (key) {
        var pVal = prev[key];
        var oVal = obj[key];

        prev[key] = mergeValues(pVal, oVal);
      });

      return prev;
    }, {});
  }

  function mergeValues(pVal, oVal) {
    if (isArray(oVal)) {
      return mergeArrays(pVal, oVal);
    }
    if (isObject(oVal)) {
      return mergeObjects(pVal, oVal);
    }
    if (isCloneableInstance(oVal)) {
      return oVal.clone();
    }
    return oVal;
  }

  function mergeArrays(pVal, oVal) {
    var arr = [];
    if (isArray(pVal)) {
      var _arr;

      arr = (_arr = arr).concat.apply(_arr, _toConsumableArray(pVal));
    }

    if (isArray(oVal)) {
      var _arr2;

      arr = (_arr2 = arr).concat.apply(_arr2, _toConsumableArray(oVal));
    }
    return arr;
  }

  function mergeObjects(pVal, oVal) {
    if (isObject(pVal)) {
      return mergeDeep(pVal, oVal);
    }

    return mergeDeep(oVal);
  }
});