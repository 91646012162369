define('ember-keen/services/keen-fetch', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({
    init: function init() {
      this._super.apply(this, arguments);
      (true && !(false) && Ember.assert('You need to run `ember g ember-keen` to generate the keen-fetch service.', false));
    }
  });
});