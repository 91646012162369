define("ember-cropster-common/helpers/svg-icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.svgIcon = svgIcon;
  _exports.svgIconIE = svgIconIE;
  _exports.checkIconExists = checkIconExists;
  _exports.svgIconHelper = svgIconHelper;
  _exports.default = void 0;

  function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _nonIterableRest(); }

  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance"); }

  function _iterableToArrayLimit(arr, i) { var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

  var viewBoxes = {
    'download-pdf': '0 0 39 15',
    'download-pdf-summary': '0 0 58 15',
    'download-xls': '0 0 39 15',
    see: '0 0 30 19',
    blind: '0 0 30 24',
    'double-blind': '0 0 30 24',
    delete: '0 0 20 26',
    'drag-n-drop': '0 0 18 30',
    'arrow-down': '0 0 30 20',
    'arrow-thin-down': '0 0 20 20',
    merge: '0 0 20 20',
    'cupping-labels': '0 0 25 20',
    'cupping-labels-color': '0 0 25 20',
    'cupping-sheets': '0 0 25 20',
    'cupping-sheets-color': '0 0 25 20',
    lab: '0 0 20 27',
    'lab-color': '0 0 20 27',
    crown: '0 0 30 20',
    profile: '0 0 30 20',
    start: '0 0 20 27',
    list: '0 0 30 20',
    more: '0 0 50 20',
    unlink: '0 0 20 24',
    'coffee-bean-roasting': '0 0 20 30',
    user: '0 0 17 20',
    comment: '0 0 20 18',
    login: '0 0 20 20',
    logout: '0 0 20 20',
    upload: '0 0 20 20',
    file: '0 0 18 20',
    location: '0 0 16 20',
    'scale-weights': '0 0 28 20',
    'cocoa-bean': '0 0 20 36',
    reorder: '0 0 26 33'
  };
  var ua = window.navigator.userAgent;
  var isIE = ua.indexOf('MSIE ') > -1 || !!ua.match(/Trident.*rv:11\./);

  function svgIcon(file, _ref) {
    var _ref$classes = _ref.classes,
        classes = _ref$classes === void 0 ? '' : _ref$classes,
        _ref$viewBox = _ref.viewBox,
        viewBox = _ref$viewBox === void 0 ? '0 0 20 20' : _ref$viewBox;
    return Ember.String.htmlSafe("<svg role=\"img\" class=\"svg icon ".concat(classes, "\" viewBox=\"").concat(viewBox, "\"><use xlink:href=\"#").concat(file, "\"></use></svg>"));
  }

  function svgIconIE(file, _ref2) {
    var _ref2$classes = _ref2.classes,
        classes = _ref2$classes === void 0 ? '' : _ref2$classes,
        _ref2$viewBox = _ref2.viewBox,
        viewBox = _ref2$viewBox === void 0 ? '0 0 20 20' : _ref2$viewBox;

    var _viewBox$split = viewBox.split(' '),
        _viewBox$split2 = _slicedToArray(_viewBox$split, 4),
        width = _viewBox$split2[2],
        height = _viewBox$split2[3];

    return Ember.String.htmlSafe("<div class=\"svg icon ".concat(classes, " icon--with-canvas\" role=\"img\">\n  <canvas class=\"icon__canvas\" width=\"").concat(width, "\" height=\"").concat(height, "\"></canvas>\n  <svg role=\"img\" class=\"icon icon--absolute ").concat(classes, "\" viewBox=\"").concat(viewBox, "\"><use xlink:href=\"#").concat(file, "\"></use></svg>\n</div>"));
  }

  function checkIconExists(file) {
    return !!document.querySelector("svg#".concat(file));
  }

  function svgIconHelper(_ref3) {
    var _ref4 = _slicedToArray(_ref3, 1),
        file = _ref4[0];

    var hash = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
    var classes = Ember.get(hash, 'class') || '';
    var viewBox = Ember.get(hash, 'viewBox');

    if (!viewBox && file) {
      viewBox = Ember.get(viewBoxes, file) || '0 0 20 20';
    }

    (true && !(checkIconExists(file)) && Ember.assert("icon \"".concat(file, "\" exists in svg-sprite"), checkIconExists(file)));
    return isIE ? svgIconIE(file, {
      classes: classes,
      viewBox: viewBox
    }) : svgIcon(file, {
      classes: classes,
      viewBox: viewBox
    });
  }
  /**
   * A helper to render an inline svg.
   *
   * @class SvgIcon
   * @namespace Helper
   * @extends Ember.Helper
   * @public
   * @example
   * ```handlebars
   * {{svg-icon 'check'}}
   * ```
   */


  var _default = Ember.Helper.helper(svgIconHelper);

  _exports.default = _default;
});