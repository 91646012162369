define("ember-cropster-common/mixins/reset-scroll", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _Ember = Ember,
      testing = _Ember.testing;
  /**
   * Scroll to top on route activation.
   *
   * @namespace EmberCropsterCommon.Mixin
   * @class ResetScroll
   * @extends Ember.Mixin
   * @public
   */

  var _default = Ember.Mixin.create({
    /**
     * On activate, scroll to top.
     *
     * @method activate
     * @override
     * @protected
     */
    activate: function activate() {
      this._super();

      var rootElement = testing ? document.querySelector('#ember-testing-container') : window;
      rootElement.scrollTo(0, 0);
    }
  });

  _exports.default = _default;
});