define("ember-cropster-models-table/components/c-table/row-sorting/component", ["exports", "ember-cropster-models-table/components/c-table/row-sorting/template"], function (_exports, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * The row containing the column titles &, if enableSorting is true, the sorting options.
   *
   * @namespace EmberCropsterModelsTable.Component.CTable
   * @class RowSorting
   * @extends Ember.Component
   * @public
   */
  var _default = Ember.Component.extend({
    layout: _template.default,
    tagName: 'tr',

    /**
     * The currently visible columns.
     *
     * @attribute visibleColumns
     * @type {EmberCropsterModelsTable.Util.Column[]}
     * @public
     */
    visibleColumns: null,

    /**
     * The initial data passed into the table.
     *
     * @attribute data
     * @type {Object[]}
     * @public
     */
    data: null,

    /**
     * The currently visible page.
     *
     * @attribute currentPageData
     * @type {Object[]}
     * @public
     */
    currentPageData: null,

    /**
     * If sorting should generally be enabled for this table.
     *
     * @attribute enableSorting
     * @type {Boolean}
     * @default true
     * @public
     */
    enableSorting: true,

    /**
     * An object with actions that is passed into all sub-components of c-table.
     *
     * @attribute actionHash
     * @type {Object}
     * @public
     */
    actionHash: null,

    /**
     * The action to call when a column sorting changes.
     *
     * @event toggleColumnSort
     * @param {EmberCropsterModelsTable.Util.Column} column
     * @param {Mixed} value
     * @public
     */
    toggleColumnSort: null
  });

  _exports.default = _default;
});