define("ember-cropster-common/helpers/eq-array-content", ["exports", "ember-cropster-common/utils/eq-array-content"], function (_exports, _eqArrayContent) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.eqArrayContent = eqArrayContent;
  _exports.default = void 0;

  function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _nonIterableRest(); }

  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance"); }

  function _iterableToArrayLimit(arr, i) { var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

  function eqArrayContent(_ref) {
    var _ref2 = _slicedToArray(_ref, 2),
        arr1 = _ref2[0],
        arr2 = _ref2[1];

    return (0, _eqArrayContent.default)(arr1, arr2);
  }
  /**
   * A helper to compare to arrays for equal content.
   *
   * @class EqArrayContent
   * @namespace Helper
   * @extends Ember.Helper
   * @public
   * @example
   * ```handlebars
   * {{eq-array-content (to-array 1 2) (to-array 1 2)}}
   * ```
   */


  var _default = Ember.Helper.helper(eqArrayContent);

  _exports.default = _default;
});