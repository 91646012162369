define("ember-cropster-models-table/components/c-table/column/toggle/component", ["exports", "ember-cropster-models-table/components/c-table/column/component", "ember-cropster-models-table/components/c-table/column/toggle/template"], function (_exports, _component, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * A column cell displaying a toggle for the row//record.
   *
   * @namespace EmberCropsterModelsTable.Component.CTable.Column
   * @class Toggle
   * @extends EmberCropsterModelsTable.Component.CTable.Column
   * @public
   */
  var _default = _component.default.extend({
    layout: _template.default,

    /**
     * If the record is selected.
     *
     * @property isSelected
     * @type {Boolean}
     * @readOnly
     * @protected
     */
    isSelected: Ember.computed('selectedItems.@each.id', 'record.id', function () {
      var id = Ember.get(this, 'record.id');
      var selectedItems = Ember.get(this, 'selectedItems') || [];
      return !!selectedItems.findBy('id', id);
    }),
    actions: {
      toggleRecord: function toggleRecord(record, isSelected) {
        var action = Ember.get(this, 'actionHash.toggleRecord');
        action(record, isSelected);
      }
    }
  });

  _exports.default = _default;
});