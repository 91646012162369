define("ember-cropster-common/utils/eq-object-content", ["exports", "ember-cropster-common/utils/eq-array-content"], function (_exports, _eqArrayContent) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = eqObjectContent;

  /*
   * Makes a deep comparison of enumerable object properties.
   *
   * @param  {Object} objectA
   * @param  {Object} objectB
   * @param  {Function} [comparatorFunction=(a,b) => a===b]
   * @return {Boolean}
   */
  function eqObjectContent(objectA, objectB, comparatorFunction) {
    comparatorFunction = Ember.typeOf(comparatorFunction) === 'function' ? comparatorFunction : function (a, b) {
      return a === b;
    }; // first: all keys must have same length

    var keysA = Object.keys(objectA).sort();
    var keysB = Object.keys(objectB).sort();

    if (keysA.length !== keysB.length) {
      return false;
    } // second: all keys should be the same


    var sameKeys = keysA.every(function (keyA, idx) {
      return keyA === keysB[idx];
    });

    if (sameKeys === false) {
      return false;
    } // check if any value is not equal


    return keysA.every(function (key) {
      var valueA = objectA[key];
      var valueB = objectB[key];

      switch (Ember.typeOf(valueA)) {
        case 'array':
          return (0, _eqArrayContent.default)(valueA, valueB, comparatorFunction);

        case 'object':
          return eqObjectContent(valueA, valueB, comparatorFunction);

        case 'instance':
          return eqObjectContent(valueA, valueB, function (a, b) {
            return Ember.get(a, 'id') === Ember.get(b, 'id');
          });

        default:
          return comparatorFunction(valueA, valueB);
      }
    });
  }
});