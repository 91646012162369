define("ember-cropster-models-table/components/c-table/table/component", ["exports", "ember-cropster-models-table/components/c-table/table/template"], function (_exports, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * The actual table element
   *
   * @namespace EmberCropsterModelsTable.Component.CTable
   * @class Table
   * @extends Ember.Component
   * @public
   */
  var _default = Ember.Component.extend({
    layout: _template.default,
    tagName: 'table',
    classNames: ['table'],
    classNameBindings: ['isStriped:table--striped', 'enableSorting:table--sortable', 'isFocusable:table--focusable'],

    /**
     * The original data that was passed into the table.
     *
     * @attribute data
     * @type {Object[]}
     * @public
     */
    data: null,

    /**
     * The currently visible data.
     *
     * @attribute currentPageData
     * @type {Object[]}
     * @public
     */
    currentPageData: null,

    /**
     * All the columns this table has, including hidden ones.
     *
     * @attribute processedColumns
     * @type {EmberCropsterModelsTable.Util.Column[]}
     * @public
     */
    processedColumns: null,

    /**
     * All the visible columns of this table.
     *
     * @attribute visibleColumns
     * @type {EmberCropsterModelsTable.Util.Column[]}
     * @public
     */
    visibleColumns: null,

    /**
     * If filtering should be enabled for this table.
     *
     * @attribute enableFiltering
     * @type {Boolean}
     * @default true
     * @public
     */
    enableFiltering: true,

    /**
     * If sorting should be enabled for this table.
     *
     * @attribute enableSorting
     * @type {Boolean}
     * @default true
     * @public
     */
    enableSorting: true,

    /**
     * The currently selected items of this table.
     *
     * @attribute selectedItems
     * @type {Object[]}
     * @public
     */
    selectedItems: null,

    /**
     * An object with actions that is passed into all sub-components of c-table.
     *
     * @attribute actionHash
     * @type {Object}
     * @public
     */
    actionHash: null,

    /**
     * If the table should be striped or not.
     *
     * @attribute isStriped
     * @type {Boolean}
     * @default true
     * @public
     */
    isStriped: true,

    /**
     * If the table should have the focusable styling or not.
     *
     * @attribute isFocusable
     * @type {Boolean}
     * @default true
     * @public
     */
    isFocusable: true
  });

  _exports.default = _default;
});