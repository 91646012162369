define("ember-cropster-common/helpers/get-value", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _nonIterableRest(); }

  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance"); }

  function _iterableToArrayLimit(arr, i) { var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

  /**
   * This helper makes a simple lookup within given
   * object on first level only (unless `dotted` is
   * false). This allows also dots in hash keys, as
   * this is not possible with ember's get() helper.
   *
   * @class GetValue
   * @namespace Helper
   * @extends Ember.Helper
   * @public
   * @example
   * ```handlebars
   * {{get-value (hash prop1=1) 'prop1' dotted=true}}
   * ```
   */
  var _default = Ember.Helper.extend({
    // -------------------------------------------------------------------------
    // Dependencies
    // -------------------------------------------------------------------------
    // Methods

    /**
     * Makes hash map lookup on first level.
     *
     * @method compute
     * @param {Array} params Positional params
     * @param {Object} [hash={}]
     * @public
     *
     * @return {String}
     */
    compute: function compute(_ref, hash) {
      var _ref2 = _slicedToArray(_ref, 2),
          obj = _ref2[0],
          key = _ref2[1];

      if (!Ember.isPresent(obj) || !Ember.isPresent(key)) {
        return undefined;
      }

      switch (Ember.typeOf(obj)) {
        case 'object':
        case 'instance':
          break;

        default:
          return undefined;
      }

      if (hash.dotted) {
        return Ember.get(obj, key);
      }

      if (!obj.hasOwnProperty(key)) {
        return undefined;
      }

      return obj[key];
    }
  });

  _exports.default = _default;
});