define("ember-indexeddb/adapters/indexed-db", ["exports", "ember-data", "ember-indexeddb/utils/clone-deep"], function (_exports, _emberData, _cloneDeep) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var JSONAPIAdapter = _emberData.default.JSONAPIAdapter;
  /**
   *
   * This Ember-Data adapter will fetch and save all data to/from IndexedDB.
   *
   * @module Ember Data
   * @class IndexedDbAdapter
   * @extends DS.JSONAPIAdapter
   * @public
   */

  var _default = JSONAPIAdapter.extend({
    /**
     * The indexedDb service.
     *
     * @property indexedDb
     * @type {IndexedDb}
     * @protected
     */
    indexedDb: Ember.inject.service(),

    /**
     * Coalesce all find requests.
     *
     * @property coalesceFindRequests
     * @type {Boolean}
     * @default true
     * @protected
     */
    coalesceFindRequests: true,

    /**
     * Set this to true to log durations of IndexedDB operations to the console.
     *
     * @property _shouldLogDurations
     * @type {Boolean}
     * @default false
     * @private
     */
    _shouldLogDurations: false,

    /**
     * This function will generate a GUID to be used in IndexedDB.
     *
     * @method generateIdForRecord
     * @return {String}
     * @protected
     */
    generateIdForRecord: function generateIdForRecord() {
      return Math.random().toString(32).slice(2).substr(0, 8);
    },

    /**
     * Fetch all records of a given type from IndexedDB.
     *
     * @method findAll
     * @param store
     * @param type
     * @return {Promise}
     * @public
     */
    findAll: function findAll(store, type) {
      var _this = this;

      var indexedDB = Ember.get(this, 'indexedDb');
      var modelName = type.modelName;

      this._logDuration("findAll ".concat(modelName));

      return new Ember.RSVP.Promise(function (resolve, reject) {
        indexedDB.findAll(modelName).then(function (records) {
          _this._logDuration("findAll ".concat(modelName), true);

          var data = _this._normalizeArray(records);

          resolve(data);
        }, reject);
      }, 'indexedDbAdapter/findAll');
    },

    /**
     * Find a record of a given type & ID from IndexedDB.
     *
     * @method findRecord
     * @param store
     * @param type
     * @param {String} id
     * @return {Promise}
     * @public
     */
    findRecord: function findRecord(store, type, id) {
      var _this2 = this;

      var indexedDB = Ember.get(this, 'indexedDb');
      var modelName = type.modelName;

      this._logDuration("findRecord ".concat(modelName, "/").concat(id));

      return new Ember.RSVP.Promise(function (resolve, reject) {
        indexedDB.find(modelName, id).then(function (record) {
          _this2._logDuration("findRecord ".concat(modelName, "/").concat(id), true);

          var data = _this2._normalizeSingle(record);

          if (!data) {
            reject("findRecord ".concat(modelName, "/").concat(id, " failed!"));
            return;
          }

          resolve(data);
        }, reject);
      }, 'indexedDbAdapter/findRecord');
    },

    /**
     * Find many records for a given type by multiple IDs.
     *
     * @method findMany
     * @param store
     * @param type
     * @param {Array} ids
     * @return {Promise}
     * @public
     */
    findMany: function findMany(store, type, ids) {
      var _this3 = this;

      var indexedDB = Ember.get(this, 'indexedDb');
      var modelName = type.modelName;

      this._logDuration("findMany ".concat(modelName, "/").concat(ids.join(',')));

      return new Ember.RSVP.Promise(function (resolve, reject) {
        indexedDB.find(modelName, ids).then(function (records) {
          _this3._logDuration("findMany ".concat(modelName, "/").concat(ids.join(',')), true);

          var data = _this3._normalizeArray(records);

          resolve(data);
        }, reject);
      }, 'indexedDbAdapter/findMany');
    },

    /**
     * Query a type from IndexedDB.
     * This will try to use real indices where possible.
     *
     * @method query
     * @param store
     * @param type
     * @param {Object} query
     * @return {Promise}
     * @public
     */
    query: function query(store, type, _query) {
      var _this4 = this;

      var indexedDB = Ember.get(this, 'indexedDb');
      var modelName = type.modelName;
      var queryString = JSON.stringify(_query);

      this._logDuration("query ".concat(modelName, " ").concat(queryString));

      return new Ember.RSVP.Promise(function (resolve, reject) {
        indexedDB.query(modelName, _query).then(function (records) {
          _this4._logDuration("query ".concat(modelName, " ").concat(queryString), true);

          var data = _this4._normalizeArray(records);

          resolve(data);
        }, reject);
      }, 'indexedDbAdapter/query');
    },

    /**
     * Query a single item from IndexedDB.
     * This will try to use real indices where possible.
     *
     * @method queryRecord
     * @param store
     * @param type
     * @param {Object} query
     * @return {Promise}
     * @public
     */
    queryRecord: function queryRecord(store, type, query) {
      var _this5 = this;

      var indexedDB = Ember.get(this, 'indexedDb');
      var modelName = type.modelName;

      this._logDuration("queryRecord ".concat(modelName));

      return new Ember.RSVP.Promise(function (resolve, reject) {
        indexedDB.queryRecord(modelName, query).then(function (record) {
          _this5._logDuration("queryRecord ".concat(modelName), true);

          var data = _this5._normalizeSingle(record);

          if (!data) {
            resolve({
              data: null
            });
            return;
          }

          resolve(data);
        }, reject);
      }, 'indexedDbAdapter/queryRecord');
    },

    /**
     * Update a given record in IndexedDB.
     *
     * @method updateRecord
     * @param store
     * @param type
     * @param snapshot
     * @return {Promise}
     * @public
     */
    updateRecord: function updateRecord(store, type, snapshot) {
      return this._save(store, type, snapshot);
    },

    /**
     * Delete a record from IndexedDB.
     *
     * @method deleteRecord
     * @param store
     * @param type
     * @param snapshot
     * @return {Promise}
     * @public
     */
    deleteRecord: function deleteRecord(store, type, snapshot) {
      var _this6 = this;

      var indexedDB = Ember.get(this, 'indexedDb');
      var modelName = type.modelName;
      var id = Ember.get(snapshot, 'id');

      this._logDuration("deleteRecord ".concat(modelName, "/").concat(id));

      return new Ember.RSVP.Promise(function (resolve, reject) {
        indexedDB.delete(modelName, id).then(function () {
          _this6._logDuration("deleteRecord ".concat(modelName, "/").concat(id), true);

          resolve(null);
        }, reject);
      }, 'indexedDbAdapter/deleteRecord');
    },

    /**
     * Create a new record in IndexedDB.
     *
     * @method createRecord
     * @param store
     * @param type
     * @param snapshot
     * @return {Promise}
     * @public
     */
    createRecord: function createRecord(store, type, snapshot) {
      return this._save(store, type, snapshot);
    },

    /**
     * This function is called under the hood by both `createRecord` and `updateRecord`.
     *
     * @method _save
     * @param store
     * @param type
     * @param snapshot
     * @return {Promise}
     * @private
     */
    _save: function _save(store, type, snapshot) {
      var _this7 = this;

      var indexedDB = Ember.get(this, 'indexedDb');
      var modelName = type.modelName;

      this._logDuration("_save ".concat(modelName, "/").concat(Ember.get(snapshot, 'id')));

      return new Ember.RSVP.Promise(function (resolve, reject) {
        var data = {};
        var serializer = store.serializerFor(modelName);
        serializer.serializeIntoHash(data, type, snapshot, {
          includeId: true
        }); // We need to make a deep clone of the data, as the data is mutated by the store later

        var record = (0, _cloneDeep.cloneDeep)(data.data);
        indexedDB.save(modelName, record.id, record).then(function () {
          _this7._logDuration("_save ".concat(modelName, "/").concat(Ember.get(snapshot, 'id')), true);

          resolve(data);
        }, reject);
      }, 'indexedDbAdapter/_save');
    },

    /**
     * This is used to normalize the response of IndexedDB for array responses.
     *
     * @method _normalizeArray
     * @param records
     * @return {Object}
     * @private
     */
    _normalizeArray: function _normalizeArray(records) {
      if (!records) {
        return {
          data: []
        };
      }

      var data = Ember.A(records).compact();
      data = Ember.A(data).mapBy('json');
      return {
        data: data
      };
    },

    /**
     * This is used to normalize a single record response.
     *
     * @method _normalizeSingle
     * @param record
     * @return {Object}
     * @private
     */
    _normalizeSingle: function _normalizeSingle(record) {
      if (!record) {
        return null;
      }

      return {
        data: Ember.get(record, 'json')
      };
    },

    /**
     * This function is used to log durations of operations to the console, if `_shouldLogDurations` is set.
     *
     * @method _logDuration
     * @param str
     * @param isEnd
     * @private
     */
    _logDuration: function _logDuration(str) {
      var isEnd = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;

      if (!Ember.get(this, '_shouldLogDurations')) {
        return;
      }
      /* eslint-disable no-console */


      if (isEnd) {
        console.timeEnd(str);
      } else {
        console.time(str);
      }
      /* eslint-enable no-console */

    }
  });

  _exports.default = _default;
});