define("ember-cropster-common/utils/promise-queue", ["exports", "ember-cropster-common/utils/promise"], function (_exports, _promise) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = promiseQueue;

  function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _nonIterableRest(); }

  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance"); }

  function _iterableToArrayLimit(arr, i) { var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

  function promiseQueue(items, func) {
    return new Ember.RSVP.Promise(function (resolve, reject) {
      var queuedItems = items.toArray();
      var values = [];

      var loadNext = function loadNext() {
        var _queuedItems$splice = queuedItems.splice(0, 1),
            _queuedItems$splice2 = _slicedToArray(_queuedItems$splice, 1),
            nextItem = _queuedItems$splice2[0];

        if (nextItem) {
          (0, _promise.resolveIfPromise)(func(nextItem)).then(function (response) {
            values.push(response);
            loadNext();
          }, reject);
        } else {
          resolve(values);
        }
      };

      loadNext();
    });
  }
});