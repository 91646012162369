define("ember-cropster-common/helpers/get-array-element", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.getArrayElement = getArrayElement;
  _exports.default = void 0;

  function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _nonIterableRest(); }

  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance"); }

  function _iterableToArrayLimit(arr, i) { var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

  function getArrayElement(_ref) {
    var _ref2 = _slicedToArray(_ref, 2),
        arr = _ref2[0],
        i = _ref2[1];

    if (Ember.typeOf(arr) !== 'array' || Ember.typeOf(i) === 'undefined') {
      return null;
    }

    return arr[i];
  }
  /**
   * Get the element at the given position from an array.
   *
   * @class GetArrayElement
   * @namespace Helper
   * @extends Ember.Helper
   * @public
   * @example
   * ```handlebars
   * {{get-array-element (to-array 'a' 'b') 1}}
   * ```
   */


  var _default = Ember.Helper.helper(getArrayElement);

  _exports.default = _default;
});