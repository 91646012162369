define("ember-cropster-models-table/components/c-table/columns-dropdown/component", ["exports", "ember-cropster-models-table/components/c-table/columns-dropdown/template"], function (_exports, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * The dropdown to select the columns to show.
   *
   * @namespace EmberCropsterModelsTable.Component.CTable
   * @class ColumnsDropdown
   * @extends Ember.Component
   * @public
   */
  var _default = Ember.Component.extend({
    layout: _template.default,

    /**
     * The columns that can be toggled.
     *
     * @attribute editableColumns
     * @type {EmberCropsterModelsTable.Util.Column[]}
     * @public
     */
    editableColumns: null,

    /**
     * An object with options for the column dropdown.
     * This contains the class to use for the dropdown, as well as eventual column sets.
     *
     * @attribute columnDropdownOptions
     * @type {{ columnSets: Object[], dropdownClass: String }}
     * @public
     */
    columnDropdownOptions: null,

    /**
     * An object with actions that is passed into all sub-components of c-table.
     *
     * @attribute actionHash
     * @type {Object}
     * @public
     */
    actionHash: null,

    /**
     * Toggle a single column's visibilty.
     *
     * @event toggleColumnHidden
     * @param {EmberCropsterModelsTable.Util.Column} column
     * @param {Boolean} isVisible
     * @public
     */
    toggleColumnHidden: null,

    /**
     * Toggle a column set.
     *
     * @event toggleColumnSet
     * @param {Object} columnSet
     * @public
     */
    toggleColumnSet: null,

    /**
     * If the divider for the dropdown should be displayed.
     *
     * @property showDivider
     * @type {Boolean}
     * @readOnly
     * @protected
     */
    showDivider: Ember.computed.bool('columnDropdownOptions.columnSets.length')
  });

  _exports.default = _default;
});