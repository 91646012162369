define("ember-cropster-models-table/components/c-table/column/list/component", ["exports", "ember-cropster-models-table/components/c-table/column/component", "ember-cropster-models-table/components/c-table/column/list/template"], function (_exports, _component, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * A column cell displaying a list of values as a string.
   * This will join the given array with a comma.
   *
   * @namespace EmberCropsterModelsTable.Component.CTable.Column
   * @class List
   * @extends EmberCropsterModelsTable.Component.CTable.Column
   * @public
   */
  var _default = _component.default.extend({
    layout: _template.default
  });

  _exports.default = _default;
});