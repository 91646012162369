define("ember-cropster-common/components/c-select/component", ["exports", "ember-cropster-common/components/c-select/template", "ember-cropster-common/utils/eq-array-content"], function (_exports, _template, _eqArrayContent) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * This is a one-way select.
   *
   * In most cases, you should use power-select instead. However, if you just need a plain `<select>` tag,
   * you can use this wrapper component.
   *
   * Note that the values DO NOT auto update, there is no two-way binding!
   *
   * @namespace Component
   * @class CSelect
   * @extends Ember.Component
   * @public
   * @example
   * ```handlebars
   * {{#c-select
   *   update=(route-action "updateValue")
   *   value="2"
   * as |cur|}}
   *   <option value=""></option>
   *   <option value="1" selected={{if (eq cur "1") true}}>First</option>
   *   <option value="2" selected={{if (eq cur "2") true}}>Second</option>
   *   <option value="3" selected={{if (eq cur "3") true}}>Third</option>
   * {{/c-select}}
   * ```
   */
  var _default = Ember.Component.extend({
    tagName: 'select',
    layout: _template.default,
    classNameBindings: ['isEmpty:select--empty'],
    attributeBindings: ['disabled', 'multiple', 'name', 'width', 'required', 'placeholder:data-placeholder'],

    /**
     * If the select box should be a multi-select.
     * Works well with searchable=true.
     *
     * @attribute multiple
     * @type {Boolean}
     * @optional
     * @public
     */
    multiple: undefined,

    /**
     * If the select box should be disabled. In this case, its values cannot be changed.
     *
     * @attribute disabled
     * @type {Boolean}
     * @optional
     * @public
     */
    disabled: undefined,

    /**
     * If the select box is required.
     * This will only do HTML5 form validation. You will still need to manually validate inputs.
     *
     * @attribute required
     * @type {Boolean}
     * @optional
     * @public
     */
    required: undefined,

    /**
     * The initial value of the select box.
     * This is yielded as | currentValue| and can be used to pre-select a value.
     *
     * @attribute value
     * @type {Mixed}
     * @optional
     * @public
     */
    value: null,

    /**
     * An optional second parameter that is added to the update action.
     *
     * @attribute actionParam
     * @type {Mixed}
     * @optional
     * @public
     */
    actionParam: null,

    /**
     * The action to call when the value changes.
     *
     * @event update
     * @param {Mixed} value
     * @public
     */
    update: null,

    /**
     * The sanitized value, to check if the value has changed.
     *
     * @property _sanitizedValue
     * @type {Mixed}
     * @private
     */
    _sanitizedValue: undefined,

    /**
     * On change event, handle the change event.
     *
     * @event change
     * @private
     */
    change: function change() {
      this._handleChangeEvent();
    },

    /**
     * Process the new value and send a changed action.
     *
     * @method _handleChangeEvent
     * @private
     */
    _handleChangeEvent: function _handleChangeEvent() {
      this._processNewValue.call(this, 'update', this._readAppropriateAttr());
    },

    /**
     * Get the appropriate value from the input.
     * This is normally the value.
     *
     * @method _readAppropriateAttr
     * @return {String|Boolean}
     * @private
     */
    _readAppropriateAttr: function _readAppropriateAttr() {
      var val = this.element.value;
      return val;
    },

    /**
     * Check if two values are equal.
     * If the values are arrays, also check for equal array contents.
     *
     * @method _isEqual
     * @param {Mixed} val1 The value to check
     * @param {Mixed} val2 The value to check against
     * @return {Boolean}
     * @private
     */
    _isEqual: function _isEqual(val1, val2) {
      if (Ember.typeOf(val1) === 'array' && Ember.typeOf(val2) === 'array') {
        return val1 === val2 || (0, _eqArrayContent.default)(val1, val2);
      }

      return val1 === val2;
    },

    /**
     * Process a new value and send a change action if it has changed.
     *
     * @method _processNewValue
     * @param {String} methodName The action name to send
     * @param {String|Boolean} rawValue The new value
     * @private
     */
    _processNewValue: function _processNewValue(methodName, rawValue) {
      var value = this.sanitizeInput(rawValue);
      var action = Ember.get(this, methodName);

      if (!this._isEqual(value, this._sanitizedValue)) {
        this._sanitizedValue = value;

        if (action) {
          if (Ember.get(this, 'actionParam')) {
            action(value, Ember.get(this, 'actionParam'));
          } else {
            action(value);
          }
        }
      }
    },

    /**
     * If currently no value is selected.
     *
     * @property isEmpty
     * @type {Boolean}
     * @readOnly
     * @public
     */
    isEmpty: Ember.computed.empty('value'),

    /**
     * This function can at some point be used to sanitize the input before it is passed to the action.
     *
     * @method _sanitizeInput
     * @param {String} input
     * @return {String}
     * @private
     */
    sanitizeInput: function sanitizeInput(input) {
      return input;
    },

    /**
     * Set the initial sanitized value.
     *
     * @method init
     * @override
     * @private
     */
    init: function init() {
      this._super.apply(this, arguments);

      this._sanitizedValue = Ember.get(this, 'value');
    },

    /**
     * Update the sanitized value.
     *
     * @method didReceiveAttrs
     * @override
     * @private
     */
    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);

      this._processNewValue.call(this, 'update', Ember.get(this, 'value'));
    }
  });

  _exports.default = _default;
});