define("ember-cropster-models-table/utils/column", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _nonIterableRest(); }

  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance"); }

  function _iterableToArrayLimit(arr, i) { var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

  /**
   * A processed column object.
   *
   * @namespace EmberCropsterModelsTable.Util
   * @class Column
   * @extends Ember.Object
   * @public
   */
  var _default = Ember.Object.extend({
    /**
     * The property name of this column.
     * By default, the property with this name of the given records will be displayed in this column.
     * This has to be unique per table, as it is also used to identify the table.
     *
     * @attribute propertyName
     * @type {String}
     * @public
     */
    propertyName: null,

    /**
     * The title of the column.
     * This should be a human readable string.
     *
     * @attribute title
     * @type {String}
     * @public
     */
    title: null,

    /**
     * If this column can be hidden/shown.
     * This basically defined if the column shows up int he column dropdown.
     *
     * @attribute mayBeHidden
     * @type {Boolean}
     * @default true
     * @public
     */
    mayBeHidden: true,

    /**
     * If the column should initially be hidden or not.
     *
     * @attribute isHidden
     * @type {Boolean}
     * @default false
     * @public
     */
    isHidden: false,

    /**
     * If this column is disabled.
     * If this is true, this will set isHidden = true & mayBeHidden=false on init.
     * It doesen't do anything on it's own, it's just a shortcut to disable it completely.
     *
     * @attribute isDisabled
     * @type {Boolean}
     * @default false
     * @public
     */
    isDisabled: false,

    /**
     * The component to use to render this column.
     * This has to be the name of a component, that ideally should extend from
     * `ember-cropster-models-table/components/c-table/column`.
     *
     * @attribute component
     * @type {String}
     * @default 'c-table/column'
     * @public
     */
    component: 'c-table/column',

    /**
     * If filtering for this column should be disabled.
     *
     * @attribute disableFiltering
     * @type {Boolean}
     * @default false
     * @public
     */
    disableFiltering: false,

    /**
     * By default, it will filter by propertyName. However, if filteredBy is set, it will instead filter by that property.
     *
     * @attribute filteredBy
     * @type {String}
     * @public
     */
    filteredBy: null,

    /**
     * If true, a select box will be shown instead of a text filter for this field.
     * If set, you also need to set filterSelectOptions!
     *
     * @attribute filterWithSelect
     * @type {Boolean}
     * @default false
     * @public
     */
    filterWithSelect: false,

    /**
     * If true, a multi select box will be shown instead of a text filter for this field.
     * If set, you also need to set filterSelectOptions!
     *
     * @attribute filterWithSelectMultiple
     * @type {Boolean}
     * @default false
     * @public
     */
    filterWithSelectMultiple: false,

    /**
     * If true, a checkbox will be shown as a filter for this column instead of a text filter.
     *
     * @attribute filterBoolean
     * @type {Boolean}
     * @default false
     * @public
     */
    filterBoolean: false,

    /**
     * If true, a date range select will be shown as a filter for this column instead of a text filter.
     *
     * @attribute filterDate
     * @type {Boolean}
     * @default false
     * @public
     */
    filterDate: false,

    /**
     * If set to a component name, this component will be rendered as filter for this column instead of a text filter.
     *
     * @attribute filterComponent
     * @type {String}
     * @default null
     * @public
     */
    filterComponent: null,

    /**
     * If `filterWithSelect` or `filterWithSelectMultiple` is true, you need to set the available options here.
     * This has to be an array of objects with value and label fields.
     *
     * You can also return a promise that resolves with an array like that.
     *
     * @attribute filterSelectOptions
     * @type {Object[]}
     * @public
     */
    filterSelectOptions: null,

    /**
     * If `filterDate` is true, this can be set to specify the options for `{{date-picker}}`, e.g. "Last year".
     *
     * @attribute filterDateOptions
     * @type {Array}
     * @public
     */
    filterDateOptions: null,

    /**
     * If filterBoolean=true, the default filter behavior is to ignore the filter if it is set to false.
     * This means by default it has the functionality of Exclude/Include: If not checked, the default set is shown, if checked, the filteredBy filter is set to true.
     * If `filterBooleanIgnoreFalse` is set to `false`, it will instead have a True/False functionality: filteredBy will always be set to true or false.
     *
     * Example where `true` makes sense: Is Archived
     * If true, isArchived will be set to true, but if not, it will be ignored (instead of being set to isArchived=false).
     * isArchived=false would have a different meaning than leaving it away.
     *
     * Example where `false` makes sense: Include Archived
     * If true, includeArchived will be set to true. If no, includeArchived will be set to false. It will never be left away.
     *
     * @attribute filterBooleanIgnoreFalse
     * @type {Boolean}
     * @default true
     * @public
     */
    filterBooleanIgnoreFalse: true,

    /**
     * The default filter value.
     *
     * @attribute filter
     * @type {Mixed}
     * @public
     */
    filter: null,

    /**
     * If sorting should be disabled for this column.
     *
     * @attribute disableSorting
     * @type {Boolean}
     * @default false
     * @public
     */
    disableSorting: false,

    /**
     * By default, it will sort by the propertyName.
     * However, if this is set, it will sort by this field instead.
     *
     * This can also be a function which will be used for sorting, e.g.: `(a, b) => a > b ? 1 : -1`
     * This should always return the ascending set, as it will be reversed internally otherwise anyhow.
     * Note that this will NOT work for paginated tables.
     *
     * @attribute sortedBy
     * @type {String|Function}
     * @public
     */
    sortedBy: null,

    /**
     * If set, sort by this field in the given direction.
     * Can be either ASC or DESC.
     *
     * @attribute sortDirection
     * @type {'ASC'|'DESC'}
     * @public
     */
    sortDirection: null,

    /**
     * The class(es) to add to each td.
     *
     * @attribute className
     * @type {String}
     * @public
     */
    className: '',

    /**
     * The class(es) to add to each th.
     * This will be added to both the filter & the sorting row!
     *
     * @attribute theadClassName
     * @type {String}
     * @public
     */
    theadClassName: '',

    /**
     * Additional class(es) to add to the filter input.
     *
     * @attribute filterInputClass
     * @type {String}
     * @public
     */
    filterInputClass: '',

    /**
     * If this column is hidden by default or not.
     * This is set on init(). If isHidden is then modified later (e.g. via the remember column settings),
     * this can be used to find the default columns.
     *
     * @property isHiddenDefault
     * @type {Boolean}
     * @default false
     * @public
     */
    isHiddenDefault: false,

    /**
     * If the column is visible.
     * This is the inverse of isHidden.
     *
     * @property isVisible
     * @type {Boolean}
     * @readOnly
     * @public
     */
    isVisible: Ember.computed.not('isHidden'),

    /**
     * The property to filter by.
     * If set, this is filteredBy. Else, it is propertyName.
     *
     * @property filterProperty
     * @type {String}
     * @readOnly
     * @public
     */
    filterProperty: Ember.computed.or('filteredBy', 'propertyName'),

    /**
     * If this column can be filtered.
     * his is false if either disableFiltering is true, or if no filter property is set.
     *
     * @property canFilter
     * @type {Boolean}
     * @readOnly
     * @public
     */
    canFilter: Ember.computed('disableFiltering', 'filterProperty', function () {
      return !Ember.get(this, 'disableFiltering') && Ember.get(this, 'filterProperty');
    }),

    /**
     * If filterWithSelect or filterWithSelectMultiple is true, this property is used to get the correct properties
     * from filter & filterSelectOptions for ember-power-select.
     *
     * This is necessary, because filter only contains the filter value (e.g. the IDs),
     * but ember-power-select compares the givens elected items for equal matches with its options.
     *
     * @property filterSelectProperty
     * @type {Object|Object[]}
     * @readOnly
     * @public
     */
    filterSelectProperty: Ember.computed('filter', 'filter.[]', 'filterSelectOptions.[]', function () {
      var filterMultiple = Ember.get(this, 'filterWithSelectMultiple');
      var filterOptions = Ember.get(this, 'filterSelectOptions');
      var filterValue = Ember.get(this, 'filter');

      if (filterMultiple) {
        if (!filterValue) {
          return null;
        }

        var filterStringArray = Ember.typeOf(filterValue) === 'string' ? filterValue.split(',') : filterValue;
        return filterOptions.filter(function (option) {
          return filterStringArray.includes(Ember.get(option, 'value'));
        });
      }

      return filterOptions ? filterOptions.findBy('value', filterValue) : null;
    }),

    /**
     * If this column is being filtered by.
       * @property hasFilter
     * @type {Boolean}
     * @readOnly
     * @public
     */
    hasFilter: Ember.computed('disableFiltering', 'filter', function () {
      return !Ember.get(this, 'disableFiltering') && !Ember.isBlank(Ember.get(this, 'filter'));
    }),

    /**
     * The property to sort by.
     * If set, this is sortedBy. Else, it is propertyName.
     *
     * @property sortProperty
     * @type {String}
     * @readOnly
     * @public
     */
    sortProperty: Ember.computed.or('sortedBy', 'propertyName'),

    /**
     * If this column can be sorted.
     * his is false if either disableSorting is true, or if no sort property is set.
     *
     * @property canSort
     * @type {Boolean}
     * @readOnly
     * @public
     */
    canSort: Ember.computed('disableSorting', 'sortProperty', function () {
      return !Ember.get(this, 'disableSorting') && Ember.get(this, 'sortProperty');
    }),

    /**
     * If this column is being sorted by.
     *
     * @property hasSort
     * @type {Boolean}
     * @readOnly
     * @public
     */
    hasSort: Ember.computed('sortDirection', 'disableSorting', function () {
      return !Ember.get(this, 'disableSorting') && Ember.get(this, 'sortDirection');
    }),
    init: function init() {
      if (!Ember.get(this, 'title')) {
        Ember.set(this, 'mayBeHidden', false);
      }

      if (Ember.get(this, 'isDisabled')) {
        Ember.set(this, 'isHidden', true);
        Ember.set(this, 'mayBeHidden', false);
      }

      Ember.set(this, 'isHiddenDefault', Ember.get(this, 'isHidden'));

      this._super.apply(this, arguments);
    },

    /**
     * The function to use for filtering.
     * In special cases, you can overwrite this.
     *
     * @method filterFunction
     * @param {Mixed} value The filter value
     * @param {Object} record
     * @return {Boolean}
     */
    filterFunction: function filterFunction(value
    /* , record*/
    ) {
      var filter = Ember.get(this, 'filter');

      if (Ember.isBlank(filter)) {
        return true;
      }

      if (Ember.get(this, 'filterDate') && Ember.typeOf(filter) === 'array') {
        var _filter = _slicedToArray(filter, 2),
            from = _filter[0],
            to = _filter[1];

        if (from && from.valueOf() > value.valueOf()) {
          return false;
        }

        if (to && to.valueOf() < value.valueOf()) {
          return false;
        }

        return true;
      }

      if (Ember.typeOf(filter) === 'array') {
        return filter.includes(value);
      }

      if (Ember.typeOf(filter) === 'string') {
        var lowerCaseFilter = filter.toLowerCase();
        var lowerCaseValue = Ember.typeOf(value) === 'string' ? value.toLowerCase() : "".concat(value).toLowerCase();
        return lowerCaseValue.includes(lowerCaseFilter);
      }

      if (Ember.typeOf(filter) === 'boolean') {
        var filterBooleanIgnoreFalse = Ember.get(this, 'filterBooleanIgnoreFalse');

        if (!filter && filterBooleanIgnoreFalse) {
          return true;
        }

        return value === filter;
      }

      return value === filter;
    }
  });

  _exports.default = _default;
});