define("ember-cropster-models-table/utils/get-current-timestamp", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.getCurrentTimestamp = getCurrentTimestamp;
  _exports.default = void 0;

  function getCurrentTimestamp() {
    if (window && window.performance && window.performance.now) {
      return window.performance.now();
    }

    return +new Date();
  }

  var _default = getCurrentTimestamp;
  _exports.default = _default;
});