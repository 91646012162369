define("ember-cropster-models-table/components/c-table/no-data/component", ["exports", "ember-cropster-models-table/components/c-table/no-data/template"], function (_exports, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * This component is rendered if no data is found for a given table.
   *
   * @namespace EmberCropsterModelsTable.Component.CTable
   * @class NoData
   * @extends Ember.Component
   * @public
   */
  var _default = Ember.Component.extend({
    layout: _template.default,
    tagName: 'tr',

    /**
     * The original data that was passed into the table.
     *
     * @attribute data
     * @type {Array}
     * @public
     */
    data: null,

    /**
     * The currently visible column.
     *
     * @attribute visibleColumns
     * @type {EmberCropsterModelsTable.Util.Column[]}
     * @public
     */
    visibleColumns: null,

    /**
     * If there was an error, e.g. while loading.
     *
     * @attribute hasError
     * @type {Boolean}
     * @default false
     * @public
     */
    hasError: false,

    /**
     * The currently set page size.
     *
     * @attribute pageSize
     * @type {Number}
     * @public
     */
    pageSize: null,

    /**
     * The currently displayed page.
     *
     * @attribute currentPage
     * @type {Number}
     * @public
     */
    currentPage: null,

    /**
     * If the reset filters button should be displayed.
     * Only if this is true, it will check if showing the button even makes any sense.
     *
     * @attribute showResetButton
     * @type {Boolean}
     * @default true
     * @public
     */
    showResetButton: true,

    /**
     * An object with actions that is passed into all sub-components of c-table.
     *
     * @attribute actionHash
     * @type {Object}
     * @public
     */
    actionHash: null,

    /**
     * The action to call to reset the filters.
     *
     * @event resetFilters
     * @public
     */
    resetFilters: null,

    /**
     * If the table currently has any filters set, or the page is not 1.
     *
     * @property hasFilters
     * @type {Boolean}
     * @readOnly
     * @protected
     */
    hasFilters: Ember.computed('visibleColumns.@each.hasFilter', 'currentPage', function () {
      var columns = Ember.get(this, 'visibleColumns') || [];
      var currentPage = Ember.get(this, 'currentPage') || 1;
      return currentPage > 1 || !!columns.findBy('hasFilter');
    }),

    /**
     * If the reset button should be displayed.
     *
     * @property shouldShowResetButton
     * @type {Boolean}
     * @readOnly
     * @protected
     */
    shouldShowResetButton: Ember.computed('showResetButton', 'data.length', 'hasFilters', function () {
      return Ember.get(this, 'showResetButton') && Ember.get(this, 'resetFilters') && (Ember.get(this, 'data.length') || Ember.get(this, 'hasFilters'));
    }),
    actions: {
      resetFilters: function resetFilters() {
        var resetFiltersAction = Ember.get(this, 'resetFilters');
        return resetFiltersAction();
      }
    }
  });

  _exports.default = _default;
});