define("ember-cropster-models-table/mixins/server-paginated-table-route", ["exports", "ember-cropster-common/utils/promise", "ember-cropster-common/utils/serialize-object"], function (_exports, _promise, _serializeObject) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _nonIterableSpread(); }

  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance"); }

  function _iterableToArray(iter) { if (Symbol.iterator in Object(iter) || Object.prototype.toString.call(iter) === "[object Arguments]") return Array.from(iter); }

  function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) { for (var i = 0, arr2 = new Array(arr.length); i < arr.length; i++) { arr2[i] = arr[i]; } return arr2; } }

  /**
   * This mixin should be used on a route that contains a c-table-paginated component.
   *
   * @namespace EmberCropsterModelsTable.Mixin
   * @class ServerPaginatedTableRoute
   * @public
   */
  var _default = Ember.Mixin.create({
    keen: Ember.inject.service(),
    userSettings: Ember.inject.service(),
    router: Ember.inject.service(),
    _cachedQueryParams: null,
    setupController: function setupController(controller, model) {
      this._cacheCurrentQueryParams();

      this._addHistoryPopStateListener(); // While loading...


      Ember.set(controller, 'isNotEmpty', true); // Wait on data to resolve

      var data = model.data || model;
      (0, _promise.resolveIfPromise)(data).then(function (data) {
        var isNotEmpty = data && Ember.get(data, 'length') || Ember.get(controller, 'hasFilters');
        Ember.set(controller, 'isNotEmpty', isNotEmpty);
      });
      return this._super.apply(this, arguments);
    },
    resetController: function resetController(controller) {
      var isExiting = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;

      if (isExiting) {
        var selectedItems = Ember.get(controller, 'selectedItems');

        if (selectedItems) {
          selectedItems.clear();
        }

        this._removeHistoryPopStateListener();
      }

      this._super.apply(this, arguments);
    },
    serializeQueryParam: function serializeQueryParam(value, urlKey) {
      if (urlKey === 'filters') {
        return (0, _serializeObject.serializeObject)(value);
      }

      return this._super.apply(this, arguments);
    },
    deserializeQueryParam: function deserializeQueryParam(value, urlKey) {
      if (urlKey === 'filters') {
        return (0, _serializeObject.deserializeObject)(value);
      }

      return this._super.apply(this, arguments);
    },
    actions: {
      paginatedTableActions: function paginatedTableActions(actionName) {
        var params = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : [];
        var options = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
        var availableActions = ['toggleRecord', 'clearSelection', 'filtersChanged', 'resetFiltersAndSelection', 'resetFilters', 'loadAndSelectAll'];

        if (!availableActions.includes(actionName)) {
          return Ember.RSVP.Promise.reject("The action ".concat(actionName, " is not available on the server paginated table route."));
        }

        return this[actionName].apply(this, _toConsumableArray(params).concat([options]));
      },
      resetFilters: function resetFilters() {
        return this.resetFilters.apply(this, arguments);
      },
      filtersChanged: function filtersChanged() {
        return this.filtersChanged.apply(this, arguments);
      },
      resetFiltersAndSelection: function resetFiltersAndSelection() {
        return this.resetFiltersAndSelection.apply(this, arguments);
      },
      toggleRecord: function toggleRecord() {
        return this.toggleRecord.apply(this, arguments);
      },
      clearSelection: function clearSelection() {
        return this.clearSelection.apply(this, arguments);
      },
      loadAndSelectAll: function loadAndSelectAll() {
        return this.loadAndSelectAll.apply(this, arguments);
      }
    },

    /**
     * Use this method in the `model(params)` hook to get a useable query.
     *
     * For example:
     *
     * ```js
     * model(params) {
     *  let query = this._getFilterQuery(params);
     *
     *  // Potentially add other, static things to query...
     *
     *  let data = get(this, 'store').query('model', query);
     *  return { data };
     * }
     * ```
     *
     * @method getFilterQuery
     * @param {Object} params
     * @returns {{page: number, pageSize: (null|*|string)}}
     * @public
     */
    getFilterQuery: function getFilterQuery(params) {
      var controller = this.controllerFor(Ember.get(this, 'routeName')) || {};
      var query = {
        page: params.page || 1,
        pageSize: params.pageSizeOverwrite || Ember.get(controller, 'defaultPageSize')
      };

      if (params.sort) {
        query.sort = params.sort || Ember.get(controller, 'defaultSort') || 'id';
        query.sortOrder = params.sortOrder || Ember.get(controller, 'defaultSortOrder') || 'DESC';
      }

      for (var i in params.filters) {
        if (params.filters.hasOwnProperty(i)) {
          query[i] = params.filters[i];
        }
      }

      return query;
    },
    // TODO: Deprecated
    _getFilterQuery: function _getFilterQuery() {
      return this.getFilterQuery.apply(this, arguments);
    },

    /**
     * Reset all the filters & refresh the model.
     *
     * @method resetFilters
     * @public
     */
    resetFilters: function resetFilters() {
      var controller = this.controllerFor(Ember.get(this, 'routeName'));
      Ember.setProperties(controller, {
        filters: Ember.get(controller, 'defaultFilters'),
        page: 1,
        pageSizeOverwrite: null,
        sort: Ember.get(controller, 'defaultSort'),
        sortOrder: Ember.get(controller, 'defaultSortOrder')
      });
      Ember.run.next(this, this._cacheCurrentQueryParams);
      return this.refresh();
    },

    /**
     * This method handles changing filters from the table, and saves the settings to query params on the controller.
     *
     * @method filtersChanged
     * @param {Object} settings
     * @public
     */
    filtersChanged: function filtersChanged(settings) {
      var _this = this;

      var controller = this.controllerFor(Ember.get(this, 'routeName'));
      var keen = Ember.get(this, 'keen');
      var pageSize = settings.pageSize,
          page = settings.page,
          sortBy = settings.sortBy,
          sortOrder = settings.sortOrder,
          filters = settings.filters;
      var options = {
        page: page,
        pageSizeOverwrite: pageSize,
        sort: sortBy || Ember.get(controller, 'defaultSort'),
        sortOrder: sortOrder || Ember.get(controller, 'defaultSortOrder'),
        filters: filters || Ember.get(controller, 'defaultFilters')
      }; // If the page size has changed, save it in user settings

      var userSettings = Ember.get(this, 'userSettings');
      var tableId = Ember.get(controller, 'tableId');

      if (tableId && pageSize !== Ember.get(controller, 'pageSize')) {
        var settingsTableId = "table-".concat(tableId, "-page-size");
        userSettings.setItem(settingsTableId, pageSize);
      }

      Ember.run.next(function () {
        Ember.setProperties(controller, options);
        Ember.run.next(_this, _this._cacheCurrentQueryParams);
      });
      keen.sendEvent(tableId, {
        query: options
      });
    },

    /**
     * Reset the filters & clear the selection in one step.
     *
     * @method resetFiltersAndSelection
     * @public
     */
    resetFiltersAndSelection: function resetFiltersAndSelection() {
      this.resetFilters();
      this.clearSelection();
    },

    /**
     * Toggle one or multiple records from the selected items.
     *
     * @method toggleRecord
     * @param {Object|Array} records
     * @param {Boolean} isSelected
     * @public
     */
    toggleRecord: function toggleRecord(records, isSelected) {
      var controller = this.controllerFor(Ember.get(this, 'routeName'));
      var selectedItems = Ember.get(controller, 'selectedItems');

      if (records.toArray) {
        records = records.toArray();
      }

      if (Ember.typeOf(records) !== 'array') {
        records = [records];
      }

      if (isSelected) {
        selectedItems.addObjects(records);
      } else {
        selectedItems.removeObjects(records);
      }
    },

    /**
     * Clear all selected items.
     *
     * @method clearSelection
     * @public
     */
    clearSelection: function clearSelection() {
      var controller = this.controllerFor(Ember.get(this, 'routeName'));
      var selectedItems = Ember.get(controller, 'selectedItems');
      selectedItems.clear();
    },

    /**
     * Get the current query params of the page.
     * This only includes the params defined in _checkableQueryParams (= the table params).
     * Other params need to be handled as usually (e.g. via refreshModel=true).
     *
     * @method _getCurrentQueryParams
     * @return {Object}
     * @private
     */
    _getCurrentQueryParams: function _getCurrentQueryParams() {
      if (!window.URLSearchParams) {
        return null;
      }

      var checkableQueryParams = Ember.get(this, '_checkableQueryParams');
      var newQueryParams = {};
      var search = window.location.search;

      if (search.startsWith('?')) {
        search = search.substr(1);
      }

      var queryParams = new URLSearchParams(search);
      queryParams.forEach(function (value, param) {
        if (checkableQueryParams.includes(param)) {
          newQueryParams[param] = value;
        }
      });
      return newQueryParams;
    },

    /**
     * Cache the current query params as a property on the route.
     *
     * @method _cacheCurrentQueryParams
     * @private
     */
    _cacheCurrentQueryParams: function _cacheCurrentQueryParams() {
      var newQueryParams = this._getCurrentQueryParams();

      Ember.set(this, '_cachedQueryParams', newQueryParams);
    },

    /**
     * This is called whenever the back or forward button in the browser is used.
     * This function checks if the query params have changed, and if they did, refresh the route.
     * This ensures that the back/forward buttons work as expected.
     *
     * @property _historyPopStateHandler
     * @type {Function}
     * @private
     */
    _historyPopStateHandler: Ember.computed(function () {
      var _this2 = this;

      return function () {
        Ember.run.next(function () {
          var routeName = Ember.get(_this2, 'fullRouteName') || Ember.get(_this2, 'routeName');
          var currentRouteName = Ember.get(_this2, 'router.currentRouteName');

          if (routeName === currentRouteName && _this2._checkQueryParamsChanged()) {
            _this2.refresh();
          }
        });
      };
    }),

    /**
     * The query params to actually check.
     * These are the params that are used by the route mixin.
     *
     * @property _checkableQueryParams
     * @type {String[]}
     * @private
     */
    _checkableQueryParams: Ember.computed(function () {
      return ['sort', 'sortOrder', 'filters', 'page', 'pageSize'];
    }),

    /**
     * Check if the query params have changed.
     *
     * @method _checkQueryParamsChanged
     * @return {Boolean}
     * @private
     */
    _checkQueryParamsChanged: function _checkQueryParamsChanged() {
      var cachedQueryParams = Ember.get(this, '_cachedQueryParams');

      var newQueryParams = this._getCurrentQueryParams();

      if (!cachedQueryParams || !newQueryParams) {
        return false;
      } // Different amount of query params?


      if (Object.keys(newQueryParams).length !== Object.keys(cachedQueryParams).length) {
        return true;
      } // Different content of query params?


      return !!Object.keys(newQueryParams).find(function (param) {
        return newQueryParams[param] !== cachedQueryParams[param];
      });
    },

    /**
     * Add an event listener for when the back/forward buttons are pressed.
     *
     * @method _addHistoryPopStateListener
     * @private
     */
    _addHistoryPopStateListener: function _addHistoryPopStateListener() {
      var handler = Ember.get(this, '_historyPopStateHandler');
      window.addEventListener('popstate', handler);
    },

    /**
     * Remove the event listener for when the back/forward buttons are pressed.
     *
     * @method _removeHistoryPopStateListener
     * @private
     */
    _removeHistoryPopStateListener: function _removeHistoryPopStateListener() {
      var handler = Ember.get(this, '_historyPopStateHandler');
      window.removeEventListener('popstate', handler);
    }
  });

  _exports.default = _default;
});