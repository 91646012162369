define("ember-cropster-models-table/components/c-table/column/component", ["exports", "ember-cropster-models-table/components/c-table/column/template"], function (_exports, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * A single column cell for a table.
   *
   * @namespace EmberCropsterModelsTable.Component.CTable
   * @class Column
   * @extends Ember.Component
   * @public
   */
  var _default = Ember.Component.extend({
    layout: _template.default,

    /**
     * The record (=row data) to display.
     *
     * @attribute record
     * @type {Object}
     * @public
     */
    record: null,

    /**
     * The column to display.
     *
     * @attribute column
     * @type {EmberCropsterModelsTable.Util.Column}
     * @public
     */
    column: null,

    /**
     * All the currently visible columns.
     *
     * @attribute visibleColumns
     * @type {EmberCropsterModelsTable.Util.Column[]}
     * @public
     */
    visibleColumns: null,

    /**
     * All the currently selected items.
     *
     * @attribute selectedItems
     * @type {Object[]}
     * @public
     */
    selectedItems: null,

    /**
     * An object with actions that is passed into all sub-components of c-table.
     *
     * @attribute actionHash
     * @type {Object}
     * @public
     */
    actionHash: null,

    /**
     * The actual value.
     * computed value is set on init(), based on the propertyName.
     *
     * @property value
     * @type {Mixed}
     * @readOnly
     * @protected
     */
    value: null,

    /**
     * The property name of the column.
     *
     * @property propertyName
     * @type {String}
     * @readOnly
     * @protected
     */
    propertyName: Ember.computed.readOnly('column.propertyName'),
    init: function init() {
      this._super.apply(this, arguments);

      this._initValueCP();
    },

    /**
     * Create a computed property for `value` which is an alias for `record.<propertyName>`.
     * Since `propertyName` is dynamic, we need to set it like this.
     * This is called on init().
     *
     * @method _initValueCP
     * @protected
     */
    _initValueCP: function _initValueCP() {
      var propertyName = Ember.get(this, 'propertyName');

      if (propertyName) {
        Ember.defineProperty(this, 'value', Ember.computed.readOnly("record.".concat(propertyName)));
      }
    }
  });

  _exports.default = _default;
});