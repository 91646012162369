define("ember-cropster-models-table/components/c-table/column/nl2br/component", ["exports", "ember-cropster-models-table/components/c-table/column/component", "ember-cropster-models-table/components/c-table/column/nl2br/template"], function (_exports, _component, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * A column cell displaying a text with line breaks.
   *
   * @namespace EmberCropsterModelsTable.Component.CTable.Column
   * @class Nl2br
   * @extends EmberCropsterModelsTable.Component.CTable.Column
   * @public
   */
  var _default = _component.default.extend({
    layout: _template.default
  });

  _exports.default = _default;
});