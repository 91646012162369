define("ember-indexeddb/utils/test-waiter", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.registerWaiter = registerWaiter;
  _exports.unregisterWaiter = unregisterWaiter;

  /* eslint-disable ember-suave/no-direct-property-access */
  // Wrap these, so they are only called when testing
  function registerWaiter() {
    if (Ember.testing) {
      return Ember.Test.registerWaiter.apply(void 0, arguments);
    }
  }

  function unregisterWaiter() {
    if (Ember.testing) {
      return Ember.Test.unregisterWaiter.apply(void 0, arguments);
    }
  }
});