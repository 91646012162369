define("ember-indexeddb/utils/log", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.log = log;

  // Log a message, unless in tests (to prevent noisy test output)
  function log() {
    // eslint-disable-next-line ember-suave/no-direct-property-access
    if (!Ember.testing) {
      var _console;

      (_console = console).log.apply(_console, arguments); // eslint-disable-line

    }
  }
});