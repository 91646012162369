define("ember-cropster-models-table/components/c-table/pagination/component", ["exports", "ember-cropster-models-table/components/c-table/pagination/template"], function (_exports, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * The pagination for the table.
   *
   * @namespace EmberCropsterModelsTable.Component.CTable
   * @class Pagination
   * @extends Ember.Component
   * @public
   */
  var _default = Ember.Component.extend({
    layout: _template.default,
    classNames: ['c-table__footer__pagination'],

    /**
     * The current page.
     *
     * @attribute currentPage
     * @type {Number}
     * @public
     */
    currentPage: null,

    /**
     * The total item count.
     *
     * @attribute totalItemCount
     * @type {Number}
     * @public
     */
    totalItemCount: null,

    /**
     * The total number of pages.
     *
     * @attribute pagesCount
     * @type {Number}
     * @public
     */
    pagesCount: null,

    /**
     * The page size.
     *
     * @attribute pageSize
     * @type {Number}
     * @public
     */
    pageSize: null,

    /**
     * An object with actions that is passed into all sub-components of c-table.
     *
     * @attribute actionHash
     * @type {Object}
     * @public
     */
    actionHash: null,

    /**
     * The action to call when the page is updated.
     *
     * @event updatePage
     * @param {Number} page
     * @public
     */
    updatePage: null,

    /**
     * The pagination groups.
     * These are then rendered.
     *
     * @property paginationGroups
     * @type {Object[]}
     * @readOnly
     * @protected
     */
    paginationGroups: Ember.computed('pagesCount', 'currentPage', function () {
      var currentPage = Ember.get(this, 'currentPage');
      var pagesCount = Ember.get(this, 'pagesCount');
      var firstPage = 1;
      var lastPage = pagesCount;

      if (firstPage === lastPage) {
        return [];
      }

      var currentGroupOffset = 2; // How many pages to show around the current page?

      var groups = [];
      var previousGroup = null;
      var nextGroup = null;
      var currentGroup = [];
      var curMin = Math.max(currentPage - currentGroupOffset, firstPage);
      var curMax = Math.min(currentPage + currentGroupOffset, lastPage);

      for (var i = curMin; i <= curMax; i++) {
        currentGroup.push({
          page: i,
          label: i,
          isActive: i === currentPage
        });
      }

      if (currentPage - currentGroupOffset === 2) {
        currentGroup.unshift({
          page: firstPage,
          label: firstPage
        });
      } else if (currentPage - currentGroupOffset > 1) {
        previousGroup = [{
          page: firstPage,
          label: firstPage
        }];
      }

      if (currentPage > firstPage) {
        var p = {
          page: currentPage - 1,
          label: 'PREV'
        };
        previousGroup ? previousGroup.unshift(p) : currentGroup.unshift(p);
      }

      if (currentPage + currentGroupOffset === lastPage - 1) {
        currentGroup.push({
          page: lastPage,
          label: lastPage
        });
      } else if (currentPage + currentGroupOffset < lastPage) {
        nextGroup = [{
          page: lastPage,
          label: lastPage
        }];
      }

      if (currentPage < lastPage) {
        var _p = {
          page: currentPage + 1,
          label: 'NEXT'
        };
        nextGroup ? nextGroup.push(_p) : currentGroup.push(_p);
      }

      if (previousGroup) {
        groups.push(previousGroup);
      }

      groups.push(currentGroup);

      if (nextGroup) {
        groups.push(nextGroup);
      }

      return groups;
    })
  });

  _exports.default = _default;
});