define("ember-cropster-models-table/utils/promise-filter-options", ["exports", "ember-data", "ember-cropster-common/utils/promise"], function (_exports, _emberData, _promise) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = promiseFilterOptions;
  var PromiseArray = _emberData.default.PromiseArray;
  /**
   * Create a promise array for filterSelectOptions of a c-table.
   * This takes an array (optionally a promise that resolves with an array),
   * and resolves as a compatible array.
   *
   * @method promiseFilterFunction
   * @param {Array|RSVP.Promise<Array> promise
   * @param {String} labelPath The property on the objects to use for the label. Defaults to `name`.
   * @param {String} valuePath The property on the object to use as the value. Defaults to `id`.
   * @return {RSVP.Promise<Object[]>}
   */

  function promiseFilterOptions(promise) {
    var _ref = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {},
        _ref$labelPath = _ref.labelPath,
        labelPath = _ref$labelPath === void 0 ? 'name' : _ref$labelPath,
        _ref$valuePath = _ref.valuePath,
        valuePath = _ref$valuePath === void 0 ? 'id' : _ref$valuePath;

    return PromiseArray.create({
      promise: new Ember.RSVP.Promise(function (resolve) {
        (0, _promise.resolveIfPromise)(promise).then(function (allItems) {
          if (!allItems) {
            allItems = [];
          }

          resolve(allItems.map(function (item) {
            var label = '';

            if (Ember.typeOf(labelPath) === 'function') {
              label = labelPath(item);
            } else {
              label = Ember.get(item, labelPath);
            }

            return {
              label: label,
              value: Ember.get(item, valuePath)
            };
          }));
        }).catch(function () {
          return resolve([]);
        });
      })
    });
  }
});