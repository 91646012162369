define("ember-cropster-models-table/components/c-table/data-loading/component", ["exports", "ember-cropster-models-table/components/c-table/data-loading/template"], function (_exports, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * This is shown while data is being loaded for the table.
   * By default, it shows a skeleton loading screen for the rows given in pageSize.
   *
   * @namespace EmberCropsterModelsTable.Component.CTable
   * @class DataLoading
   * @extends Ember.Component
   * @public
   */
  var _default = Ember.Component.extend({
    layout: _template.default,
    tagName: '',

    /**
     * The passed in data.
     * This is the initial query of the table.
     *
     * @attribute data
     * @type {Array}
     * @public
     */
    data: null,

    /**
     * The previously visible page data.
     *
     * @attribute currentPageData
     * @type {Array}
     * @public
     */
    currentPageData: null,

    /**
     * The currently visible columns.
     *
     * @attribute visibleColumns
     * @type {EmberCropsterModelsTable.Util.Column[]}
     * @public
     */
    visibleColumns: null,

    /**
     * The currently used page size.
     *
     * @attribute pageSize
     * @type {Number}
     * @public
     */
    pageSize: null,

    /**
     * An object with actions that is passed into all sub-components of c-table.
     *
     * @attribute actionHash
     * @type {Object}
     * @public
     */
    actionHash: null,

    /**
     * An array of dummy-rows to display.
     * The content of the array doesn't matter, it is only used to iterate dummy rows via {{#each}}.
     *
     * If there was previously data displayed, use that amount of rows for the loading template.
     * Else, use the page size.
     * And if that is also not available, use 10.
     * Also, cap it at a maximum of 200.
     *
     * @property rows
     * @type {Array}
     * @protected
     */
    rows: Ember.computed('pageSize', 'currentPageData.length', function () {
      var currentPageDataLength = Ember.get(this, 'currentPageData.length');
      var pageSize = currentPageDataLength || Ember.get(this, 'pageSize') || 10;
      pageSize = Math.min(pageSize, 200);
      var arr = [];

      for (var i = 0; i < pageSize; i++) {
        arr.push(i);
      }

      return arr;
    })
  });

  _exports.default = _default;
});