define("ember-indexeddb/utils/clone-deep", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.cloneDeep = cloneDeep;
  _exports.default = void 0;

  function isObject(obj) {
    return Ember.typeOf(obj) === 'object' && obj.constructor === Object;
  }

  function isArray(obj) {
    return Ember.typeOf(obj) === 'array';
  }

  function isCloneableInstance(obj) {
    return Ember.typeOf(obj) === 'instance' && Ember.typeOf(obj.clone) === 'function';
  }
  /**
   * Deep-clone an object.
   *
   * @namespace EmberIndexeddb.Util
   * @method cloneDeep
   * @param {Object} obj An object to clone deeply
   * @return {Object} The cloned object
   * @public
   */


  function cloneDeep(obj) {
    var data = {};
    Object.keys(obj).forEach(function (key) {
      var value = obj[key];
      data[key] = cloneValue(value);
    });
    return data;
  }

  var _default = cloneDeep;
  _exports.default = _default;

  function cloneValue(value) {
    if (isArray(value)) {
      return cloneArray(value);
    }

    if (isObject(value)) {
      return cloneDeep(value);
    }

    if (isCloneableInstance(value)) {
      return value.clone();
    }

    return value;
  }

  function cloneArray(value) {
    return value.map(cloneValue);
  }
});