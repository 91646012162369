define("ember-cropster-common/utils/union-array", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = unionArray;

  /*
   * Unifies two arrays into a single one by
   * checking with a comparator function.
   *
   * @param  {Array} arrayA
   * @param  {Array} arrayB
   * @param  {Function} [comparatorFunction=(a,b) => a===b]
   * @return {Array}
   */
  function unionArray(arrayA, arrayB, comparatorFunction) {
    // If no comparator function is given, just add arrayB to arrayA (without duplicates)
    if (Ember.typeOf(comparatorFunction) !== 'function') {
      var _union = arrayA.toArray();

      arrayB.forEach(function (item) {
        return _union.addObject(item);
      });
      return _union;
    }

    var union = arrayA.concat(arrayB);

    for (var i = 0; i < union.length; i++) {
      for (var j = i + 1; j < union.length; j++) {
        var a = union[i];
        var b = union[j];

        if (comparatorFunction(a, b)) {
          // replace with latter item
          union.splice(i, 1, b); // and remove latter item

          union.splice(j--, 1);
        }
      }
    }

    return union;
  }
});