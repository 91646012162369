define("ember-l10n/test-helpers", ["exports", "ember-l10n/services/l10n"], function (_exports, _l10n) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _default;

  function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _nonIterableRest(); }

  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance"); }

  function _iterableToArrayLimit(arr, i) { var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

  function _default(context) {
    var tHelper = Ember.Helper.helper(function (_ref, hash) {
      var _ref2 = _slicedToArray(_ref, 1),
          str = _ref2[0];

      return (0, _l10n.strfmt)(str, Ember.assign({}, hash));
    });
    var ptHelper = Ember.Helper.helper(function (_ref3, hash) {
      var _ref4 = _slicedToArray(_ref3, 1),
          str
      /*, ctxt*/
      = _ref4[0];

      return (0, _l10n.strfmt)(str, Ember.assign({}, hash));
    });
    var nHelper = Ember.Helper.helper(function (_ref5, hash) {
      var _ref6 = _slicedToArray(_ref5, 3),
          strSingular = _ref6[0],
          strPlural = _ref6[1],
          count = _ref6[2];

      return (0, _l10n.strfmt)(count !== 1 ? strPlural : strSingular, Ember.assign({
        count: count
      }, hash));
    });
    var pnHelper = Ember.Helper.helper(function (_ref7, hash) {
      var _ref8 = _slicedToArray(_ref7, 3),
          strSingular = _ref8[0],
          strPlural = _ref8[1],
          count
      /*, ctxt*/
      = _ref8[2];

      return (0, _l10n.strfmt)(count !== 1 ? strPlural : strSingular, Ember.assign({
        count: count
      }, hash));
    });
    context.register('helper:t', tHelper);
    context.register('helper:n', nHelper);
    context.register('helper:pt', ptHelper);
    context.register('helper:pn', pnHelper);
  }
});