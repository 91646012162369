define("ember-cropster-common/utils/dom/event-listener", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.addEventListener = addEventListener;
  _exports.removeEventListener = removeEventListener;
  var _cache = {};

  function addEventListener(el, event, id, func) {
    var key = "".concat(event, ".").concat(id); // If an event listener already exists, remove it

    removeEventListener(el, event, id);
    el.addEventListener(event, func);
    _cache[key] = func;
  }

  function removeEventListener(el, event, id) {
    var key = "".concat(event, ".").concat(id);
    var func = _cache[key];

    if (func) {
      el.removeEventListener(event, func);
      _cache[key] = undefined;
    }
  }
});