define('ember-keen/mixins/keen-track-pageview', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({

    keen: Ember.inject.service(),

    /**
     * This property is set to true when the performance tracking has already started before this route is entered.
     * This means that the model_load_time will include time from the parent route.
     *
     * @property _wasAlreadyTracking
     * @type {Boolean}
     * @default false
     * @protected
     */
    _wasAlreadyTracking: false,

    /**
     * Setup a listener to check how long the page view took.
     *
     * @method beforeModel
     * @param {Transition} transition
     * @override
     * @return {*}
     * @public
     */
    beforeModel: function beforeModel() {
      (true && !(!this.keen._isTrackingPageViews) && Ember.assert('You have set up automatic page view tracking - please remove the KeenTrackPageView mixin', !this.keen._isTrackingPageViews));
      (true && !(false) && Ember.deprecate('The keen page view mixin has been deprecated in favor of using `keen.trackAllPageViews()`, which will automate tracking over all pages. This requires ember-source@3.6 to work.', false, { id: 'ember-keen.page-view-mixin', until: '4.0.0' }));


      this._setLoadTrackStart();
      return this._super.apply(this, arguments);
    },


    /**
     * Set the start time of the page load on the keen service.
     *
     * @method _setLoadTrackStart
     * @protected
     */
    _setLoadTrackStart: function _setLoadTrackStart() {
      var keen = Ember.get(this, 'keen');

      if (keen.isPerformanceTracking('page-view')) {
        Ember.set(this, '_wasAlreadyTracking', true);
      }

      keen.startPerformanceTrack('page-view');
    },


    /**
     * Get the query params for tracking the page view.
     *
     * @method _getTrackingParams
     * @return {Object}
     * @protected
     */
    _getTrackingParams: function _getTrackingParams() {
      return this.paramsFor(Ember.get(this, 'routeName')) || {};
    },


    /**
     * Get the route name of the page to track.
     *
     * @method _getTrackingRouteName
     * @return {String}
     * @protected
     */
    _getTrackingRouteName: function _getTrackingRouteName() {
      // In engines, fullRouteName will give you the full path
      return Ember.get(this, 'fullRouteName') || Ember.get(this, 'routeName');
    },


    /**
     * Get the time it took to enter the route
     *
     * @method _getPageLoadTime
     * @return {Number}
     * @protected
     */
    _getPageLoadTime: function _getPageLoadTime() {
      return Ember.get(this, 'keen').endPerformanceTrack('page-view');
    },


    /**
     * Get the render time.
     * This returns a promise that resolves after rendering.
     *
     * @method _getPageRenderTime
     * @return {RSVP.Promise<Number>}
     * @protected
     */
    _getPageRenderTime: function _getPageRenderTime() {
      var _this = this;

      var keen = Ember.get(this, 'keen');
      keen.startPerformanceTrack('page-view-render-time');

      return new Ember.RSVP.Promise(function (resolve) {
        Ember.run.schedule('afterRender', _this, function () {
          var timeDiff = keen.endPerformanceTrack('page-view-render-time');
          resolve(timeDiff);
        });
      });
    },


    /**
     * Get the render time, and then track the page view.
     *
     * @method _trackPageViewWithRender
     * @private
     */
    _trackPageViewWithRender: function _trackPageViewWithRender() {
      this._getPageRenderTime().then(this._trackPageView.bind(this));
    },


    /**
     * Track a page view for a given transition with Keen.IO.
     *
     * @method _trackPageView
     * @protected
     */
    _trackPageView: function _trackPageView() {
      var renderTime = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 0;

      var keen = Ember.get(this, 'keen');

      if (!renderTime || Ember.typeOf(renderTime) !== 'number') {
        renderTime = 0;
      }

      var queryParams = this._getTrackingParams();
      var routeName = this._getTrackingRouteName();
      var modelLoadTime = this._getPageLoadTime();

      // If the performance tracking started before this route, it means that it includes load time from it's parent
      // This can have an effect on the model load time, and thus can be used to further drill down into load times
      var wasAlreadyTracking = Ember.get(this, '_wasAlreadyTracking');
      Ember.set(this, '_wasAlreadyTracking', false);

      var totalTime = renderTime + (modelLoadTime || 0);

      /* eslint-disable camelcase */
      var keenData = {
        page: routeName,
        query_params: queryParams,
        previous_page: Ember.get(keen, '_previousPage'),
        performance: {
          total_time: totalTime,
          model_load_time: modelLoadTime,
          render_time: renderTime,
          total_time_seconds: (totalTime / 1000).toFixed(2) * 1,
          model_load_time_seconds: (modelLoadTime / 1000).toFixed(2) * 1,
          render_time_seconds: (renderTime / 1000).toFixed(2) * 1,
          model_load_time_includes_parent: wasAlreadyTracking
        }
      };

      Ember.set(keen, '_previousPage', {
        page: keenData.page,
        query_params: keenData.query_params
      });
      /* eslint-enable camelcase */

      keen.sendEvent('page-view', keenData);
    },


    actions: {
      didTransition: function didTransition() {
        this._super.apply(this, arguments);
        this._trackPageViewWithRender();

        return true;
      }
    }

  });
});