define("ember-cropster-models-table/components/c-table-paginated/quick-action-bar-content/component", ["exports", "ember-cropster-common/utils/promise"], function (_exports, _promise) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * The quick action bar content receives some fields to work with.
   *
   * @namespace EmberCropsterModelsTable.Component.CTablePaginated
   * @class QuickActionBarContent
   * @extends Ember.Component
   * @public
   */
  var _default = Ember.Component.extend({
    /**
     * The currently selected items.
     *
     * @attribute selectedItems
     * @type {Object[]}
     * @public
     */
    selectedItems: null,

    /**
     * The base data query.
     *
     * @attribute data
     * @type {Object[]}
     * @public
     */
    data: null,

    /**
     * The currently used tableId.
     *
     * @attribute tableId
     * @type {String}
     * @public
     */
    tableId: null,

    /**
     * The general data from the paginated table controller.
     *
     * @attribute paginatedTableData
     * @type {Object[]}
     * @public
     */
    paginatedTableData: null,

    /**
     * The max. number of pages.
     *
     * @attribute maxPageCount
     * @type {Number}
     * @public
     */
    maxPageCount: null,

    /**
     * The count of total items across all pages.
     *
     * @attribute totalItemCount
     * @type {Number}
     * @public
     */
    totalItemCount: null,

    /**
     * The number of items on the current page.
     *
     * @attribute currentItemCount
     * @type {Number}
     * @public
     */
    currentItemCount: null,

    /**
     * The action hash that is passed into all sub-components.
     *
     * @attribute actionHash
     * @type {Object}
     * @public
     */
    actionHash: null,

    /**
     * The currently selected items' ids.
     *
     * @property selectedIds
     * @type {String[]}
     * @readOnly
     * @protected
     */
    selectedIds: Ember.computed.mapBy('selectedItems', 'id'),
    actions: {
      callActionThenClearSelection: function callActionThenClearSelection(action) {
        for (var _len = arguments.length, args = new Array(_len > 1 ? _len - 1 : 0), _key = 1; _key < _len; _key++) {
          args[_key - 1] = arguments[_key];
        }

        return this._callActionThenClearSelection.apply(this, [action].concat(args));
      }
    },

    /**
     * Call a given action with the given arguments.
     * When the action resolves, clear the selection.
     * This is a helpful meta-action - use it e.g. like this:
     *
     * `action=(action 'callActionThenClearSelection' actionHash.deleteLot lot)`
     *
     * @method _callActionThenClearSelection
     * @param {Function} action The actual action function (!) to call
     * @param {Mixed} args The remaining arguments after the action will be passed to the action.
     * @return {RSVP.Promise}
     * @private
     */
    _callActionThenClearSelection: function _callActionThenClearSelection(action) {
      var _this = this;

      for (var _len2 = arguments.length, args = new Array(_len2 > 1 ? _len2 - 1 : 0), _key2 = 1; _key2 < _len2; _key2++) {
        args[_key2 - 1] = arguments[_key2];
      }

      var promise = action.apply(void 0, args);
      (0, _promise.resolveIfPromise)(promise).then(function () {
        if (!Ember.get(_this, 'isDestroyed')) {
          var clearSelection = Ember.get(_this, 'actionHash.clearSelection');
          clearSelection();
        }
      });
      return promise;
    }
  });

  _exports.default = _default;
});