define("ember-cordova-events/services/ember-cordova/events", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // from https://cordova.apache.org/docs/en/4.0.0/cordova_events_events.md.html
  var CORDOVA_EVENTS = Ember.A(['deviceready', 'pause', 'resume', 'resign', 'active', 'backbutton', 'menubutton', 'searchbutton', 'startcallbutton', 'endcallbutton', 'volumedownbutton', 'volumeupbutton', 'batterycritical', 'batterylow', 'batterystatus', 'online', 'offline']);

  var _default = Ember.Service.extend(Ember.Evented, {
    _listeners: undefined,
    _ready: undefined,
    _readyHasTriggered: false,
    init: function init() {
      this._super();

      this._ready = Ember.RSVP.defer();

      this._setupListeners();
    },
    willDestroy: function willDestroy() {
      this._rejectPendingReadyPromise();

      this._teardownListeners();

      this._super();
    },
    ready: function ready() {
      return this._readyHasTriggered ? Ember.RSVP.resolve() : this._ready.promise;
    },
    on: function on(name, target, method) {
      if (name === 'deviceready' && this._readyHasTriggered) {
        Ember.run.join(target, method);
      }

      return this._super(name, target, method);
    },
    _setupReadyPromise: Ember.on('deviceready', function () {
      this._readyHasTriggered = true;

      this._ready.resolve();

      this._ready = null;
    }),
    _setupListeners: function _setupListeners() {
      var _this = this;

      var listeners = [];
      (true && !(Ember.isBlank(this._listeners)) && Ember.assert('listeners have already been set up', Ember.isBlank(this._listeners)));
      CORDOVA_EVENTS.forEach(function (name) {
        var listener = {
          name: name,
          method: function method(e) {
            _this.trigger(name, e);
          }
        };
        listeners.push(listener);
        document.addEventListener(listener.name, listener.method, true);
      });
      this._listeners = listeners;
    },
    _rejectPendingReadyPromise: function _rejectPendingReadyPromise() {
      if (Ember.isPresent(this._ready)) {
        this._ready.reject();

        this._ready = null;
      }
    },
    _teardownListeners: function _teardownListeners() {
      this._listeners.forEach(function (listener) {
        var name = listener.name,
            method = listener.method;
        document.removeEventListener(name, method, true);
      });
    }
  });

  _exports.default = _default;
});