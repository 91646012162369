define("ember-cropster-models-table/components/c-table/table-footer/component", ["exports", "ember-cropster-models-table/components/c-table/table-footer/template"], function (_exports, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * The table footer (tfoot) of the table.
   * This is by default empty, but can be overwritten.
   *
   * @namespace EmberCropsterModelsTable.Component.CTable
   * @class TableFooter
   * @extends Ember.Component
   * @public
   */
  var _default = Ember.Component.extend({
    layout: _template.default,
    tagName: 'tfoot',

    /**
     * The original data that was passed into the table.
     *
     * @attribute data
     * @type {Object[]}
     * @public
     */
    data: null,

    /**
     * The currently visible data.
     *
     * @attribute currentPageData
     * @type {Object[]}
     * @public
     */
    currentPageData: null,

    /**
     * All the columns this table has, including hidden ones.
     *
     * @attribute processedColumns
     * @type {EmberCropsterModelsTable.Util.Column[]}
     * @public
     */
    processedColumns: null,

    /**
     * All the visible columns of this table.
     *
     * @attribute visibleColumns
     * @type {EmberCropsterModelsTable.Util.Column[]}
     * @public
     */
    visibleColumns: null,

    /**
     * The currently selected items of this table.
     *
     * @attribute selectedItems
     * @type {Object[]}
     * @public
     */
    selectedItems: null,

    /**
     * An object with actions that is passed into all sub-components of c-table.
     *
     * @attribute actionHash
     * @type {Object}
     * @public
     */
    actionHash: null
  });

  _exports.default = _default;
});