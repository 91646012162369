define("ember-cropster-models-table/components/c-table/row-filtering/component", ["exports", "ember-cropster-models-table/components/c-table/row-filtering/template"], function (_exports, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * The row containing the filter cells.
   * This will only be rendered if enableFiltering is true.
   *
   * @namespace EmberCropsterModelsTable.Component.CTable
   * @class RowFiltering
   * @extends Ember.Component
   * @public
   */
  var _default = Ember.Component.extend({
    layout: _template.default,
    tagName: 'tr',

    /**
     * The currently visible columns.
     *
     * @attribute visibleColumns
     * @type {EmberCropsterModelsTable.Util.Column[]}
     * @public
     */
    visibleColumns: null,

    /**
     * The initial data passed into the table.
     *
     * @attribute data
     * @type {Object[]}
     * @public
     */
    data: null,

    /**
     * The currently visible page.
     *
     * @attribute currentPageData
     * @type {Object[]}
     * @public
     */
    currentPageData: null,

    /**
     * The currently selected items.
     *
     * @attribute selectedItems
     * @type {Object[]}
     * @public
     */
    selectedItems: null,

    /**
     * If data is currently being loaded.
     *
     * @attribute isLoading
     * @type {Boolean}
     * @default false
     * @public
     */
    isLoading: false,

    /**
     * An object with actions that is passed into all sub-components of c-table.
     *
     * @attribute actionHash
     * @type {Object}
     * @public
     */
    actionHash: null,

    /**
     * The action to call when a column filter changes.
     *
     * @event updateColumnFilter
     * @param {EmberCropsterModelsTable.Util.Column} column
     * @param {Mixed} value
     * @public
     */
    updateColumnFilter: null,

    /**
     * If all items of the current page are selected.
     *
     * @property allItemsSelected
     * @type {Boolean}
     * @readOnly
     * @protected
     */
    allItemsSelected: Ember.computed('isLoading', 'selectedItems.@each.id', 'currentPageData.@each.id', function () {
      if (Ember.get(this, 'isLoading')) {
        return false;
      }

      var currentPageData = Ember.get(this, 'currentPageData');
      var selectedItems = Ember.get(this, 'selectedItems') || [];
      var selectedIds = selectedItems.mapBy('id');

      if (!currentPageData || !Ember.get(selectedIds, 'length')) {
        return false;
      }

      return Ember.isNone(currentPageData.mapBy('id').find(function (id) {
        return !selectedIds.includes(id);
      }));
    }),
    actions: {
      updateColumnFilterSelect: function updateColumnFilterSelect(column, isMultiple, val) {
        if (isMultiple && Ember.typeOf(val) === 'array') {
          val = val.mapBy('value');
        }

        if (!isMultiple && Ember.typeOf(val) === 'object') {
          val = val.value;
        }

        var action = Ember.get(this, 'updateColumnFilter');
        return action(column, val);
      },
      toggleAll: function toggleAll() {
        return this._toggleAll.apply(this, arguments);
      }
    },

    /**
     * Toggle all records.
     * This will try to use a toggleRecordAll action on the actionHash, else falling back to toggleRecord.
     * You can specify your own custom toggleRecordAll action for custom behavior.
     *
     * @method _toggleAll
     * @private
     */
    _toggleAll: function _toggleAll() {
      var action = Ember.get(this, 'actionHash.toggleRecordAll') || Ember.get(this, 'actionHash.toggleRecord');
      return action.apply(void 0, arguments);
    }
  });

  _exports.default = _default;
});